import React, { Suspense, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ChatgptContext } from '../../chatgpt_summarize/context_chatgpt/ChatgptContext';
import Loader from './LayoutComponents/Loader/Loader';
import { UserProfileContext } from '../../store/context/profile.context';
import { routes } from '../../common/routes';

function AppNavigator({ isFullwwidth }) {
  const profileContext = useContext(UserProfileContext);

  const { profileInfo = {} } = profileContext;
  console.log(profileInfo, 'klklprofileInfo');
  const { setSidebarStatus, sidebarStatus } = useContext(ChatgptContext);
  // if (sidebarStatus && isFullwwidth) return null;

  return (
    <Routes>
      {routes.map((page) => {
        const { component: Component, exact, name, path, index } = page;
        console.log(name, path, 'pathwewe');

        if (
          name == 'PortfolioInsightsCompititor' &&
          (!profileInfo?.CompetitorIntelligence?.subscription_requested ||
            profileInfo?.CompetitorIntelligence?.subscription_status !=
              'Approved')
        ) {
          return null;
        } else if (
          name == 'PortfolioInsights' &&
          (!profileInfo?.CompetitorIntelligence?.subscription_requested ||
            profileInfo?.CompetitorIntelligence?.subscription_status !=
              'Approved')
        ) {
          return null;
        } else if (
          name == 'Linkedin Public Page Insights' &&
          (!profileInfo?.LinkedInSocialListening?.subscription_requested ||
            profileInfo?.LinkedInSocialListening?.subscription_status !=
              'Approved')
        ) {
          return null;
        } else if (
          name == 'Linkedin Insights' &&
          (!profileInfo?.LinkedInOwnAccountIntelligence
            ?.subscription_requested ||
            profileInfo?.LinkedInOwnAccountIntelligence?.subscription_status !=
              'Approved')
        ) {
          return null;
        } else if (
          name == 'Lead Generation' &&
          (!profileInfo?.LeadIQInsights?.subscription_requested ||
            profileInfo?.LeadIQInsights?.subscription_status != 'Approved')
        ) {
          return null;
        } else if (
          name == 'SocialMedia' &&
          (!profileInfo?.LeadIQInsights?.subscription_requested ||
            profileInfo?.LeadIQInsights?.subscription_status != 'Approved')
        ) {
          return null;
        } else if (
          name == 'Trust Pilot' &&
          (!profileInfo?.TrustpilotIntelligence?.subscription_requested ||
            profileInfo?.TrustpilotIntelligence?.subscription_status !=
              'Approved')
        ) {
          return null;
        } else if (
          name == 'Job Insights' &&
          (!profileInfo?.LeadIQInsights?.subscription_requested ||
            profileInfo?.LeadIQInsights?.subscription_status != 'Approved')
        ) {
          return null;
        } else if (
          name == 'genai summary' &&
          (!profileInfo?.GenAISocialInsights?.subscription_requested ||
            profileInfo?.GenAISocialInsights?.subscription_status != 'Approved')
        ) {
          return null;
        } else if (
          name == 'ActionableInsights' &&
          (!profileInfo?.SocialListening?.subscription_requested ||
            profileInfo?.SocialListening?.subscription_status != 'Approved')
        ) {
          return null;
        } else if (
          name == 'Company Details' &&
          (!profileInfo?.CompanyMarketIntelligence?.subscription_requested ||
            profileInfo?.CompanyMarketIntelligence?.subscription_status !=
              'Approved')
        ) {
          return null;
        } else if (
          name == 'Alerts' &&
          (!profileInfo?.Alerts?.subscription_requested ||
            profileInfo?.Alerts?.subscription_status != 'Approved')
        ) {
          return null;
        } else if (
          name == 'PDF Copilot Assistant' &&
          (!profileInfo?.UploadPDF?.subscription_requested ||
            profileInfo?.UploadPDF?.subscription_status != 'Approved')
        ) {
          return null;
        } else if (
          name == 'Gen AI social media search' &&
          (!profileInfo?.GenAiSocialMediaSearch?.subscription_requested ||
            profileInfo?.GenAiSocialMediaSearch?.subscription_status !=
              'Approved')
        ) {
          return null;
        } else if (
          name == 'Gen AI Insights' &&
          (!profileInfo?.UtilityFunctions?.subscription_requested ||
            profileInfo?.UtilityFunctions?.subscription_status != 'Approved')
        ) {
          return null;
        } else if (
          name == 'Utility Functions' &&
          (!profileInfo?.UtilityFunctions?.subscription_requested ||
            profileInfo?.UtilityFunctions?.subscription_status != 'Approved')
        ) {
          return null;
        } else {
          return (
            <Route
              path={path}
              element={
                <Suspense
                  fallback={
                    <Loader message="Our AI is generating insights from millions of social media data " />
                  }
                >
                  <Component />
                </Suspense>
              }
              exact={exact}
              key={name}
              index={index}
            />
          );
        }
      })}
    </Routes>
  );
}

export default React.memo(AppNavigator);
