import React, { useState } from 'react';

function SidebarLinkGroup({
  children,
  activecondition,
  link,
  label,
  profileInfo,
}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  if (
    label == 'HaiX AI Assistant' &&
    (!profileInfo?.GenAISocialInsights?.subscription_requested ||
      profileInfo?.GenAISocialInsights?.subscription_status != 'Approved')
  ) {
    return null;
  } else if (
    label == 'Real Time Social Listening Insights' &&
    (!profileInfo?.SocialListening?.subscription_requested ||
      profileInfo?.SocialListening?.subscription_status != 'Approved')
  ) {
    return null;
  } else if (
    label == 'Lead Generation Insights' &&
    (!profileInfo?.LeadIQInsights?.subscription_requested ||
      profileInfo?.LeadIQInsights?.subscription_status != 'Approved')
  ) {
    return null;
  }
  // else if (
  //   label == "PortFolio/Competitor Insights" &&
  //   !profileInfo?.CompetitorIntelligence
  //     ?.subscription_requested
  // ) {
  //   return null;
  // }
  else if (
    label == 'LinkedIn Insights' &&
    !profileInfo?.LinkedInSocialListening?.subscription_requested &&
    !profileInfo?.LinkedInOwnAccountIntelligence?.subscription_requested &&
    profileInfo?.LinkedInSocialListening?.subscription_status != 'Approved' &&
    profileInfo?.LinkedInOwnAccountIntelligence?.subscription_status !=
      'Approved'
  ) {
    debugger;
    return null;
  } else if (
    label == 'Company Intelligence' &&
    (!profileInfo?.CompanyMarketIntelligence?.subscription_requested ||
      profileInfo?.CompanyMarketIntelligence?.subscription_status != 'Approved')
  ) {
    return null;
  } else if (
    label == 'Alerts' &&
    (!profileInfo?.Alerts?.subscription_requested ||
      profileInfo?.Alerts?.subscription_status != 'Approved')
  ) {
    return null;
  } else if (
    label == 'Upload PDF' &&
    (!profileInfo?.UploadPDF?.subscription_requested ||
      profileInfo?.UploadPDF?.subscription_status != 'Approved')
  ) {
    return null;
  } else if (
    label == 'Trust Pilot Insights' &&
    (!profileInfo?.TrustpilotIntelligence?.subscription_requested ||
      profileInfo?.TrustpilotIntelligence?.subscription_status != 'Approved')
  ) {
    return null;
  } else if (
    label == 'Marketing Campaign Insights' &&
    (!profileInfo?.MarketingCampaignsMeta?.subscription_requested ||
      profileInfo?.MarketingCampaignsMeta?.subscription_status != 'Approved')
  ) {
    return null;
  } else if (
    label == 'Utility Functions' &&
    (!profileInfo?.UtilityFunctions?.subscription_requested ||
      profileInfo?.UtilityFunctions?.subscription_status != 'Approved')
  ) {
    return null;
  } else {
    return (
      <li
        className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
          link !== '' &&
          window.location.pathname !== '/' &&
          window.location.pathname
            .slice(1)
            .startsWith(
              link === '/'
                ? window.location.pathname !== '/socialinsights'
                  ? 'ignorSlashPath'
                  : link.slice(1)
                : link.slice(1),
            ) &&
          'bg-slate-900'
        }`}
      >
        {children(handleClick, open)}
      </li>
    );
  }
}

export default React.memo(SidebarLinkGroup);
