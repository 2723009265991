import './FlipCard.css'; // Import the CSS file

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@uirack/components/ui/avatar';
import {
  BellIcon,
  CheckIcon,
  InfoCircledIcon,
  Pencil1Icon,
} from '@radix-ui/react-icons';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@uirack/components/ui/card';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@uirack/components/ui/popover';
import React, { useContext, useEffect, useState } from 'react';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@uirack/components/ui/sheet';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@uirack/components/ui/tooltip';

import { AiOutlineClose } from 'react-icons/ai';
import { Button } from '@uirack/components/ui/button';
import { Check } from 'lucide-react';
import { Checkbox } from '@uirack/components/ui/checkbox';
import DataList from '../InsightsDashboard/SearchContainer/DataList';
import NumOfPostComments from '../InsightsDashboard/SearchContainer/NumOfPostComments';
import NumOfPostCommentsNew from '../InsightsDashboard/SearchContainer/NumOfPostCommentsNew';
import Options from '../Components/Options';
import PropTypes from 'prop-types';
import { SOCIAL_MEDIA_TYPE } from '../../common/constants';
import { Switch } from '@uirack/components/ui/switch';
import { cn } from '@uirack/lib/utils';
import useId from './useId';
import useSearchTermCustomHook from '../customHooks/useSearchTerm.customHook';
import { v4 as uuidv4 } from 'uuid';

const NewChannelChoosWithoutKeyword = ({
  mediaItem,
  socialMediaSearchValues,
  searchTerm,
  mainSearchTerm,
  companyInfo,
  setSocialMediaSearchValues,
  setMediaSearchInput,
  showNew,
  masterSearchQuery,
  option,
  onMediaSourceToggle,
  handleOptionSelect,
  youtubeOption,
  twitterOption,
  tiktokOption,
  instagramOption,
  facebookOption,
  setInstagramType,
  setTiktokType,
  setYoutubeType,
  className,
  ...props
}) => {
  // ================================Var==========================
  // const ids = useId()
  const socialMedia = companyInfo?.mediaSearchKeys
    ? mediaItem.label.toLowerCase() === 'news'
      ? 'news'
      : mediaItem.label.toLowerCase()
    : mediaItem.label.toLowerCase() === 'news'
    ? 'articles'
    : mediaItem.label.toLowerCase();

  const { searchTermLoader, searchTermResponse, handleSearch, error } =
    useSearchTermCustomHook();
  const { icon, label, state, id } = mediaItem;
  const ids = useId();
  const [searchKey, setSearchKey] = useState(
    masterSearchQuery || mainSearchTerm,
  );
  const [currentSelection, setCurrentSelection] = useState();
  const [currentSelectionFaceBook, setCurrentSelectionFaceBook] = useState();

  const [channelState, setChannelState] = useState(
    !!companyInfo?.mediaSearchKeys?.[mediaItem.label.toLowerCase()] || false,
  );

  const [isActiveChannel, setIsActiveChannel] = useState(
    mediaItem.label.toLowerCase() != 'facebook'
      ? companyInfo?.[socialMedia] ||
          companyInfo?.mediaSearchKeys?.[socialMedia]
      : companyInfo?.mainSearchTerm ||
          companyInfo?.mediaSearchKeys?.[socialMedia],
  );
  const [isActiveTab, setIsActiveTab] = useState([]);
  const [isActive, setIsActive] = useState('userAccount');

  const [open, setOpen] = useState(null);

  const [keywordHandler, setKeywordHandler] = useState({
    [mediaItem.label.toLowerCase()]:
      companyInfo?.[socialMedia] ||
      companyInfo?.mediaSearchKeys?.[socialMedia] ||
      '',
  });
  const [inputValue, setInputValue] = useState(
    mediaItem.label.toLowerCase() != 'facebook'
      ? companyInfo?.[socialMedia] ||
          companyInfo?.mediaSearchKeys?.[socialMedia]
      : companyInfo?.mainSearchTerm,
  );

  const [value, setValue] = useState('');
  // =============================================================

  const [stats, setStats] = useState(
    !!companyInfo?.[socialMedia] ||
      !!companyInfo?.mediaSearchKeys?.[socialMedia] ||
      false,
  );
  console.log(
    !!companyInfo?.[socialMedia] ||
      !!companyInfo?.mediaSearchKeys?.[socialMedia] ||
      false,
    stats,
    socialMedia,
    companyInfo,
    keywordHandler,
    companyInfo?.[socialMedia],
    companyInfo?.mediaSearchKeys?.[socialMedia],

    'statspropsTest',
  );
  console.log(
    keywordHandler?.[mediaItem.label.toLowerCase()],
    'statspropdfdsTeasdadst',
  );
  console.log(
    keywordHandler?.[mediaItem.label.toLowerCase()] ||
      inputValue ||
      masterSearchQuery,

    'statspropsTeasdadst',
  );
  console.log(
    mediaItem.label.toLowerCase() == 'facebook'
      ? currentSelectionFaceBook || inputValue || masterSearchQuery
      : keywordHandler?.[mediaItem.label.toLowerCase()] ||
          inputValue ||
          masterSearchQuery,

    'statspropsTeasddsdadst',
  );

  useEffect(() => {
    if (mediaItem.label.toLowerCase() != 'facebook') {
      setKeywordHandler({
        [mediaItem.label.toLowerCase()]:
          companyInfo?.[socialMedia] ||
          companyInfo?.mediaSearchKeys?.[socialMedia] ||
          '',
      });
    } else {
      setCurrentSelectionFaceBook(companyInfo?.facebookScreenName);
      setKeywordHandler({
        [mediaItem.label.toLowerCase()]:
          companyInfo?.[socialMedia] ||
          companyInfo?.mediaSearchKeys?.[socialMedia] ||
          '',
      });
    }
  }, [companyInfo]);

  // ======check state ===
  function checkKeywordHandler(keywordHandler) {
    const allEmpty = Object.values(keywordHandler).every(
      (value) => value === '',
    );
    return !allEmpty;
  }
  // useEffect(() => {
  //   setStats(checkKeywordHandler(keywordHandler));
  // }, [keywordHandler]);

  const handleSwitchChange = (newState) => {
    console.log(newState, 'aksdjk');

    setStats(newState);
    if (!newState) {
      onMediaSourceToggle(mediaItem.id, false);
    }
  };

  const checkedHandler = (isChecked) => {
    console.log(
      isChecked,
      inputValue,
      mediaItem.label.toLowerCase(),
      'kjkjksdsdj',
    );
    if (isChecked) {
      setKeywordHandler((prev) => ({
        ...prev,
        [mediaItem.label.toLowerCase()]: inputValue || masterSearchQuery,
      }));
    } else {
      setKeywordHandler((prev) => ({
        ...prev,
        [mediaItem.label.toLowerCase()]: '',
      }));
    }
  };
  const handleMediaSearch = (value) => {
    setSearchKey(value);
    setInputValue(value);
    setSocialMediaSearchValues((prevData) => ({
      ...prevData,
      [mediaItem.label.toLowerCase()]: value,
    }));
    setKeywordHandler((prev) => ({
      ...prev,
      [mediaItem.label.toLowerCase()]: value,
    }));
  };
  // =================openKeyword Options===================

  // useEffect(() => {
  //     handleMediaSearch(masterSearchQuery);
  // }, [masterSearchQuery]);

  useEffect(() => {
    updateInstagramOption(currentSelection, isActive);
  }, [currentSelection]);

  const handleOpenChange = (cardId) => {
    setOpen(cardId); // Open the clicked card and close others
  };

  const handleChannelClick = (dataItem) => {
    debugger;
    setOpen(false);
    setIsActive(dataItem.value);

    if (dataItem.value === currentSelection) {
      setCurrentSelection();
      setMediaSearchInput(mediaItem.label.toLowerCase(), undefined);
    } else {
      if (mediaItem.label.toLowerCase() === 'facebook') {
        setCurrentSelection(dataItem.value);
        setCurrentSelectionFaceBook(dataItem.label);
        setMediaSearchInput(
          mediaItem.label.toLowerCase(),
          dataItem.value,
          dataItem.label,
        );
      } else if (mediaItem.label.toLowerCase() === 'linkedin') {
        setCurrentSelection(dataItem.value);
        setMediaSearchInput(
          mediaItem.label.toLowerCase(),
          dataItem.value,
          dataItem.url,
        );
      } else {
        setCurrentSelection(dataItem.value);
        setMediaSearchInput(
          mediaItem.label.toLowerCase(),
          dataItem.value,
          dataItem.value,
        );
      }
    }
  };
  function updateInstagramOption(currentSelection, tabName) {
    setKeywordHandler((prev) => ({
      ...prev,
      [mediaItem.label.toLowerCase()]: currentSelection,
    }));
  }
  // ====================================

  // =======================save in api ======================

  // ===========================================================

  const [isFlipped, setIsFlipped] = useState(false);

  // Function to toggle flip state
  const handleFlipToggle = () => {
    setIsFlipped(!isFlipped);
  };

  console.log(
    mediaItem.label,
    keywordHandler,
    companyInfo,
    'aklsdljalskdsdkeywordHandlerdjlk',
  );
  return (
    <>
      <Card
        className={cn(
          'w-[280px] h-[400px] flex flex-col flip-card-container bg-white',
          'flip-card-inner',
          { flipped: isFlipped },
          className,
        )}
        {...props}
        id={ids}
      >
        <div className="flip-card-front">
          <CardHeader className="p-4 pb-0">
            <CardTitle>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <div
                    className={stats ? '' : 'opacity-50 pointer-events-none'}
                  >
                    <Avatar className="flex justify-center items-center">
                      <AvatarImage
                        className="h-6 w-6"
                        src={`/icons/social-media-icons/${
                          state || isActiveChannel ? 'colored' : 'glyph'
                        }/${icon}.svg`}
                      />
                      <AvatarFallback>{label}</AvatarFallback>
                    </Avatar>
                  </div>
                  <CardDescription
                    className={stats ? '' : 'opacity-50 pointer-events-none'}
                  >
                    <div className="mx-4">
                      {masterSearchQuery ||
                        socialMediaSearchValues[
                          mediaItem?.label.toLowerCase()
                        ] ||
                        searchTerm ||
                        mainSearchTerm}
                    </div>
                    {!showNew && (
                      <Sheet>
                        <SheetTrigger asChild>
                          <Button
                            variant="link"
                            className="text-xs -my-1 text-muted"
                            onClick={handleFlipToggle}
                          >
                            Available Data
                          </Button>
                        </SheetTrigger>
                        {/* <SheetContent>
                        <SheetHeader>
                          <SheetTitle>Available Data</SheetTitle>
                        </SheetHeader>
                        {companyInfo?.mediaSearchKeys?.[
                          mediaItem.label.toLowerCase()
                        ] && (
                          <NumOfPostComments
                            socialMedia={mediaItem.label.toLowerCase()}
                            mainSearchTerm={mainSearchTerm}
                          />
                        )}
                      </SheetContent> */}
                      </Sheet>
                    )}
                  </CardDescription>
                </div>
                <div className="flex-end">
                  <div className="text-black visible">
                    <Switch
                      checked={stats}
                      onCheckedChange={handleSwitchChange}
                      className="bg-gray-500"
                    />
                  </div>
                </div>
              </div>
            </CardTitle>
          </CardHeader>
          {/* ============================Input Box======================= */}
          <div
            className={cn({
              'pointer-events-none opacity-50': !stats, // Disable interaction and make it semi-transparent when stats is false
            })}
          >
            <div
              className={`relative flex items-center justify-center mx-[15%] ${
                mediaItem.state && 'mt-4'
              } ${isActiveChannel && 'mt-4'}} `}
            >
              <input
                type="text"
                onFocus={() => {
                  onMediaSourceToggle(mediaItem.id, true);
                  setIsActiveChannel(true);
                  handleChannelClick({
                    key:
                      inputValue ||
                      masterSearchQuery ||
                      socialMediaSearchValues[mediaItem.label.toLowerCase()] ||
                      searchTerm ||
                      mainSearchTerm,
                    label:
                      inputValue ||
                      masterSearchQuery ||
                      socialMediaSearchValues[mediaItem.label.toLowerCase()] ||
                      searchTerm ||
                      mainSearchTerm,
                    value:
                      inputValue ||
                      masterSearchQuery ||
                      socialMediaSearchValues[mediaItem.label.toLowerCase()] ||
                      searchTerm ||
                      mainSearchTerm,
                    url:
                      inputValue ||
                      masterSearchQuery ||
                      socialMediaSearchValues[mediaItem.label.toLowerCase()] ||
                      searchTerm ||
                      mainSearchTerm,
                  });
                }}
                value={
                  inputValue ||
                  masterSearchQuery ||
                  socialMediaSearchValues[mediaItem.label.toLowerCase()] ||
                  searchTerm ||
                  mainSearchTerm
                }
                name={`${mediaItem.label}-search`}
                onChange={(e) => {
                  handleMediaSearch(e.target.value);
                }}
                className={`rounded-md appearance-none border border-primary text-base focus:outline-none focus:ring-1 pt-1 pb-1`}
              />
              {/* <div className="absolute right-3 top-2 pointer-events-none text-primary">
<span className="material-icons text-md">edit</span>
</div> */}
            </div>

            {/* ================================================================== */}

            {/* ===============Center========================= */}
            <div className="pt-4">
              {isActiveChannel &&
                (mediaItem.label.toLowerCase() === 'twitter' ||
                  mediaItem.label.toLowerCase() === 'news' ||
                  mediaItem.label.toLowerCase() === 'reddit' ||
                  mediaItem.label.toLowerCase() === 'facebook' ||
                  mediaItem.label.toLowerCase() === 'linkedin') && (
                  <CardContent className="grid gap-4 pb-2">
                    {/* =======================userAccount========================== */}
                    <div>
                      <div className="mb-2 grid grid-cols-[25px_1fr_25px] items-start justify-between pb-2 last:mb-0 last:pb-0">
                        <div className="flex h-full items-center justify-center ">
                          <Checkbox
                            checked={
                              keywordHandler?.[
                                mediaItem.label.toLowerCase()
                              ] !== ''
                            }
                            onCheckedChange={(isChecked) =>
                              checkedHandler(isChecked)
                            }
                          />
                        </div>

                        <div className="space-y-1">
                          <p className="flex items-center text-sm text-muted-foreground">
                            {/* {keywordHandler?.[mediaItem.label.toLowerCase()] ||
                              inputValue} */}
                            {mediaItem.label.toLowerCase() == 'facebook'
                              ? currentSelectionFaceBook ||
                                inputValue ||
                                masterSearchQuery
                              : keywordHandler?.[
                                  mediaItem.label.toLowerCase()
                                ] ||
                                inputValue ||
                                masterSearchQuery}
                            <Popover
                              key={'userAccount'}
                              open={open === 'userAccount'}
                              side="bottom"
                              onOpenChange={() =>
                                handleOpenChange('userAccount')
                              }
                            >
                              <PopoverTrigger asChild>
                                <Button
                                  variant="ghost"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSearch(
                                      searchKey,
                                      mediaItem.label.toLowerCase(),
                                      undefined,
                                      isActiveChannel,
                                    );
                                  }}
                                  aria-label="Edit"
                                >
                                  <Pencil1Icon />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent className="w-[280px] ml-[60px] h-[400px] p-2  bg-white  overflow-auto custom-scrollbarsmallCard">
                                <div className="flex items-start justify-end mr-2">
                                  <button onClick={() => setOpen(false)}>
                                    <AiOutlineClose color="#ff0000" />
                                  </button>
                                </div>
                                {searchTermLoader ? (
                                  <div className="flex justify-center items-center w-full h-full">
                                    <div className="loader"></div>{' '}
                                    {/* Replace with your loader component or spinner */}
                                  </div>
                                ) : searchTermResponse.length > 0 ? (
                                  searchTermResponse.map((resultItem) => (
                                    <DataList
                                      key={uuidv4()}
                                      dataItem={resultItem}
                                      handleChannelClick={handleChannelClick}
                                      currentSelection={currentSelection}
                                    />
                                  ))
                                ) : (
                                  <div className="flex justify-center items-center w-full h-full">
                                    <span>No results found</span>{' '}
                                    {/* Message when no data is found */}
                                  </div>
                                )}
                              </PopoverContent>
                            </Popover>
                          </p>
                        </div>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button variant="ghost" size="icon">
                                <InfoCircledIcon className="h-4 w-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent className="bg-gray-200 text-black">
                              <p>
                                {
                                  keywordHandler?.[
                                    mediaItem.label.toLowerCase()
                                  ]
                                }
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </div>

                    {/* ===================================================== */}
                  </CardContent>
                )}
            </div>
            {/* ================================================ */}
          </div>
        </div>
        <div className="flip-card-back ">
          {companyInfo?.[mediaItem.label.toLowerCase()] ||
            (companyInfo?.mediaSearchKeys?.[socialMedia] && (
              <NumOfPostCommentsNew
                socialMedia={mediaItem.label.toLowerCase()}
                mainSearchTerm={mainSearchTerm}
                handleFlipToggle={handleFlipToggle}
              />
            ))}
        </div>
      </Card>
      {/* <Button
        className={`mt-4 w-full bg-gray-200 text-black hover:bg-gray-800 hover:text-white ${
          stats ? '' : 'opacity-50 pointer-events-none'
        }`}
        onClick={handleFlipToggle}
      >
        <CheckIcon className="mr-2 h-4 w-4" /> Available Data
      </Button> */}
    </>
  );
};

export default NewChannelChoosWithoutKeyword;
