import {
  INSTAGRAM_TYPE,
  INTERVAL,
  SOCIAL_MEDIA_TYPE,
  TIKTOK_TYPE,
} from '../../../common/constants';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import React, { useEffect, useState } from 'react';

import Loader from '../../App/LayoutComponents/Loader/Loader';
import useStatsSearchCustomHook from '../../customHooks/useStatsSearch.customHook';
const NumOfPostComments = ({ mainSearchTerm, socialMedia }) => {
  const [dateRange, setDateRange] = useState('');
  const [availableDataToggle, setAvailableDataToggle] = useState(false);
  const [tiktoksubtab, setTiktoksubtab] = useState(0);

  const [instagramsubtab, setInstagramsubtab] = useState(0);

  const [availableDataToggleUserAcc, setAvailableDataToggleUserAcc] =
    useState(false);
  const [availableDataToggleUserKeyword, setAvailableDataToggleUserKeyword] =
    useState(false);
  const [availableDataToggleUserHashtag, setAvailableDataToggleUserHashtag] =
    useState(false);

  const [availableDataToggleUserusertag, setAvailableDataToggleUserusertag] =
    useState(false);

  const [tiktokUserData, setTiktokUserData] = useState('');
  const [tiktokkeyworddata, settiktokkeyworddata] = useState('');
  const [tiktokHashtag, settiktokHashtag] = useState('');

  const [instagramUserData, setInstagramUserData] = useState('');

  const [instagramUsertagData, setIstagramUsertagData] = useState('');

  const [instagramHashtag, setinstagramHashtagData] = useState('');

  const {
    socialMediaResponse,
    socialMediaLoader,
    socialMediaError,
    handleAllApi,
  } = useStatsSearchCustomHook({
    searchObject: {
      [socialMedia]: mainSearchTerm,
      days: 2,
      lang: 'en',
      option: undefined,
      country: undefined,
      wordsToMatch: undefined,
      wordsNotToMatch: undefined,
    },
    // post_id: seletedID,
    endDate: undefined,
    startDate: undefined,
    interval: INTERVAL.total,
    ig_type: INSTAGRAM_TYPE[instagramsubtab] || 'manual_users',
    tiktok_type: TIKTOK_TYPE[tiktoksubtab] || 'userposts',
    type: 'daterangeofpostsandcomment',
  });

  useEffect(() => {
    const data =
      socialMediaResponse[socialMedia]?.data?.stats?.[socialMedia]
        ?.timelineStats?.timeline;
    setDateRange(data);

    if (socialMedia == SOCIAL_MEDIA_TYPE.tiktok && tiktoksubtab == 0) {
      setTiktokUserData(data);
      if (!socialMediaLoader) {
        setTiktoksubtab(1);
      }
    }
    if (socialMedia == SOCIAL_MEDIA_TYPE.tiktok && tiktoksubtab == 1) {
      settiktokHashtag(data);
      if (!socialMediaLoader) {
        setTiktoksubtab(2);
      }
    }
    if (socialMedia == SOCIAL_MEDIA_TYPE.tiktok && tiktoksubtab == 2) {
      settiktokkeyworddata(data);
    }

    if (socialMedia == SOCIAL_MEDIA_TYPE.instagram && instagramsubtab == 0) {
      setInstagramUserData(data);
      if (!socialMediaLoader) {
        setInstagramsubtab(1);
      }
    }
    if (socialMedia == SOCIAL_MEDIA_TYPE.instagram && instagramsubtab == 1) {
      setIstagramUsertagData(data);
      if (!socialMediaLoader) {
        setInstagramsubtab(2);
      }
    }
    if (socialMedia == SOCIAL_MEDIA_TYPE.instagram && instagramsubtab == 2) {
      setinstagramHashtagData(data);
    }
  }, [socialMediaResponse]);

  function formatDate(dateString) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Parse the given date string
    const date = new Date(dateString);

    // Get the month, day, and year
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date
    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
  }
  return (
    <div>
      <div className="flex gap-1 mb-2 px-2 items-center">
        <label className="block text-md font-medium  ">Available Data</label>
        <span onClick={() => setAvailableDataToggle(!availableDataToggle)}>
          {' '}
          {!availableDataToggle ? (
            <div className=" cursor-pointer">
              <IoIosArrowDown />
            </div>
          ) : (
            <div className=" cursor-pointer">
              <IoIosArrowUp />
            </div>
          )}
        </span>
      </div>
      {!availableDataToggle
        ? // <Loader message="Our AI is generating insights from millions of social media data" />
          null
        : availableDataToggle && (
            <div className="ml-2">
              {socialMedia == SOCIAL_MEDIA_TYPE.tiktok && (
                <>
                  {tiktokUserData && (
                    <div className="flex gap-1 mb-2  items-center">
                      <label className="block text-sm font-medium  ">
                        User Account
                      </label>
                      <span
                        onClick={() => {
                          setAvailableDataToggleUserAcc(
                            !availableDataToggleUserAcc,
                          );
                        }}
                      >
                        {' '}
                        {!availableDataToggleUserAcc ? (
                          <div
                            onClick={() => {
                              setTiktoksubtab(0);
                              // setAvailableDataToggleUserHashtag(false);
                              // setAvailableDataToggleUserKeyword(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}

                  {availableDataToggleUserAcc &&
                    socialMedia == SOCIAL_MEDIA_TYPE.tiktok && (
                      <>
                        <p className="font-medium text-md">Data available:</p>
                        {tiktokUserData?.posts_oldest_date &&
                          tiktokUserData?.posts_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Posts :{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(tiktokUserData?.posts_oldest_date)}{' '}
                              </span>
                              to{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(tiktokUserData?.posts_latest_date)}
                              </span>
                              .{' '}
                              {tiktokUserData?.num_posts && (
                                <span>
                                  Total : {tiktokUserData?.num_posts}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                        {tiktokUserData?.comments_oldest_date &&
                          tiktokUserData?.comments_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Comments :{' '}
                              <span className="text-secondary font-medium">
                                {' '}
                                {formatDate(
                                  tiktokUserData?.comments_oldest_date,
                                )}{' '}
                              </span>
                              to{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  tiktokUserData?.comments_latest_date,
                                )}
                              </span>
                              .{' '}
                              {tiktokUserData?.num_comments && (
                                <span>
                                  Total : {tiktokUserData?.num_comments}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                      </>
                    )}

                  {tiktokkeyworddata && (
                    <div className="flex gap-1 mb-2  items-center">
                      <label className="block text-sm  font-medium ">
                        Keyword
                      </label>
                      <span
                        onClick={() => {
                          setAvailableDataToggleUserKeyword(
                            !availableDataToggleUserKeyword,
                          );
                          // setTiktoksubtab(1);
                        }}
                      >
                        {' '}
                        {!availableDataToggleUserKeyword ? (
                          <div
                            onClick={() => {
                              setTiktoksubtab(1);
                              // setAvailableDataToggleUserHashtag(false);
                              // setAvailableDataToggleUserAcc(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}
                  {availableDataToggleUserKeyword &&
                    socialMedia == SOCIAL_MEDIA_TYPE.tiktok && (
                      <>
                        {/* <p className="font-medium text-md">Data available:</p> */}
                        {tiktokkeyworddata?.posts_oldest_date &&
                          tiktokkeyworddata?.posts_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Posts :{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  tiktokkeyworddata?.posts_oldest_date,
                                )}{' '}
                              </span>
                              -{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  tiktokkeyworddata?.posts_latest_date,
                                )}
                              </span>
                              .{' '}
                              {tiktokkeyworddata?.num_posts && (
                                <span>
                                  Total : {tiktokkeyworddata?.num_posts}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                        {tiktokkeyworddata?.comments_oldest_date &&
                          tiktokkeyworddata?.comments_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Comments :{' '}
                              <span className="text-secondary font-medium">
                                {' '}
                                {formatDate(
                                  tiktokkeyworddata?.comments_oldest_date,
                                )}{' '}
                              </span>
                              -{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  tiktokkeyworddata?.comments_latest_date,
                                )}
                              </span>
                              .{' '}
                              {tiktokkeyworddata?.num_comments && (
                                <span>
                                  Total : {tiktokkeyworddata?.num_comments}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                      </>
                    )}
                  {tiktokHashtag && (
                    <div className="flex gap-1 mb-2  items-center">
                      <label className="block text-sm font-medium ">
                        Hashtag
                      </label>
                      <span
                        onClick={() => {
                          setAvailableDataToggleUserHashtag(
                            !availableDataToggleUserHashtag,
                          );
                          // setTiktoksubtab(2);
                        }}
                      >
                        {' '}
                        {!availableDataToggleUserHashtag ? (
                          <div
                            onClick={() => {
                              setTiktoksubtab(2);
                              // setAvailableDataToggleUserKeyword(false);
                              // setAvailableDataToggleUserAcc(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}
                  {availableDataToggleUserHashtag &&
                    socialMedia == SOCIAL_MEDIA_TYPE.tiktok && (
                      <>
                        {/* <p className="font-medium text-md">Data available:</p> */}
                        {tiktokHashtag?.posts_oldest_date &&
                          tiktokHashtag?.posts_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Posts :{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(tiktokHashtag?.posts_oldest_date)}{' '}
                              </span>
                              -{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(tiktokHashtag?.posts_latest_date)}
                              </span>
                              .{' '}
                              {dateRange?.num_posts && (
                                <span>Total: {tiktokHashtag?.num_posts} </span>
                              )}{' '}
                            </p>
                          )}
                        {tiktokHashtag?.comments_oldest_date &&
                          tiktokHashtag?.comments_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Comments{' '}
                              <span className="text-secondary font-medium">
                                {' '}
                                {formatDate(
                                  tiktokHashtag?.comments_oldest_date,
                                )}{' '}
                              </span>
                              -{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  tiktokHashtag?.comments_latest_date,
                                )}
                              </span>
                              .{' '}
                              {tiktokHashtag?.num_comments && (
                                <span>
                                  Total : {tiktokHashtag?.num_comments}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                      </>
                    )}
                </>
              )}

              {socialMedia == SOCIAL_MEDIA_TYPE.instagram && (
                <>
                  {instagramUserData && (
                    <div className="flex gap-1 mb-2 items-center">
                      <label className="block text-sm font-medium ">
                        User Account
                      </label>
                      <span
                        onClick={() =>
                          setAvailableDataToggleUserAcc(
                            !availableDataToggleUserAcc,
                          )
                        }
                      >
                        {' '}
                        {!availableDataToggleUserAcc ? (
                          <div
                            onClick={() => {
                              setInstagramsubtab(0);
                              // setAvailableDataToggleUserusertag(false);
                              // setAvailableDataToggleUserHashtag(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}

                  {availableDataToggleUserAcc &&
                    socialMedia == SOCIAL_MEDIA_TYPE.instagram && (
                      <>
                        {/* <p className="font-medium text-md">Data available:</p> */}
                        {instagramUserData?.posts_oldest_date &&
                          instagramUserData?.posts_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Posts{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  instagramUserData?.posts_oldest_date,
                                )}{' '}
                              </span>
                              -{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  instagramUserData?.posts_latest_date,
                                )}
                              </span>
                              .{' '}
                              {instagramUserData?.num_posts && (
                                <span>
                                  Total : {instagramUserData?.num_posts}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                        {instagramUserData?.comments_oldest_date &&
                          instagramUserData?.comments_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Comments{' '}
                              <span className="text-secondary font-medium">
                                {' '}
                                {formatDate(
                                  instagramUserData?.comments_oldest_date,
                                )}{' '}
                              </span>
                              -{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  instagramUserData?.comments_latest_date,
                                )}
                              </span>
                              .{' '}
                              {instagramUserData?.num_comments && (
                                <span>
                                  Total : {instagramUserData?.num_comments}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                      </>
                    )}
                  {instagramUsertagData && (
                    <div className="flex gap-1 mb-2  items-center">
                      <label className="block text-sm font-medium ">
                        User Tag
                      </label>
                      <span
                        onClick={() =>
                          setAvailableDataToggleUserusertag(
                            !availableDataToggleUserusertag,
                          )
                        }
                      >
                        {' '}
                        {!availableDataToggleUserusertag ? (
                          <div
                            onClick={() => {
                              setInstagramsubtab(1);
                              // setAvailableDataToggleUserHashtag(false);
                              // setAvailableDataToggleUserAcc(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}

                  {availableDataToggleUserusertag &&
                    socialMedia == SOCIAL_MEDIA_TYPE.instagram && (
                      <>
                        {/* <p className="font-medium text-md">Data available:</p>  */}
                        {instagramUsertagData?.posts_oldest_date &&
                          instagramUsertagData?.posts_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Posts{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  instagramUsertagData?.posts_oldest_date,
                                )}{' '}
                              </span>
                              -{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  instagramUsertagData?.posts_latest_date,
                                )}
                              </span>
                              .{' '}
                              {instagramUsertagData?.num_posts && (
                                <span>
                                  Total : {instagramUsertagData?.num_posts}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                        {instagramUsertagData?.comments_oldest_date &&
                          instagramUsertagData?.comments_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Comments{' '}
                              <span className="text-secondary font-medium">
                                {' '}
                                {formatDate(
                                  instagramUsertagData?.comments_oldest_date,
                                )}{' '}
                              </span>
                              -{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  instagramUsertagData?.comments_latest_date,
                                )}
                              </span>
                              .{' '}
                              {instagramUsertagData?.num_comments && (
                                <span>
                                  Total : {instagramUsertagData?.num_comments}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                      </>
                    )}
                  {instagramHashtag && (
                    <div className="flex gap-1 mb-2 items-center">
                      <label className="block text-sm font-medium ">
                        Hashtag
                      </label>
                      <span
                        onClick={() =>
                          setAvailableDataToggleUserHashtag(
                            !availableDataToggleUserHashtag,
                          )
                        }
                      >
                        {' '}
                        {!availableDataToggleUserHashtag ? (
                          <div
                            onClick={() => {
                              setInstagramsubtab(2);
                              // setAvailableDataToggleUserusertag(false);
                              // setAvailableDataToggleUserAcc(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}

                  {availableDataToggleUserHashtag &&
                    socialMedia == SOCIAL_MEDIA_TYPE.instagram && (
                      <>
                        {/* <p className="font-medium text-md">Data available:</p> */}
                        {instagramHashtag?.posts_oldest_date &&
                          instagramHashtag?.posts_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Posts{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  instagramHashtag?.posts_oldest_date,
                                )}{' '}
                              </span>
                              -{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  instagramHashtag?.posts_latest_date,
                                )}
                              </span>
                              .{' '}
                              {instagramHashtag?.num_posts && (
                                <span>
                                  Total : {instagramHashtag?.num_posts}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                        {instagramHashtag?.comments_oldest_date &&
                          instagramHashtag?.comments_latest_date && (
                            <p className="text-sm">
                              {' '}
                              Comments{' '}
                              <span className="text-secondary font-medium">
                                {' '}
                                {formatDate(
                                  instagramHashtag?.comments_oldest_date,
                                )}{' '}
                              </span>
                              -{' '}
                              <span className="text-secondary font-medium">
                                {formatDate(
                                  instagramHashtag?.comments_latest_date,
                                )}
                              </span>
                              .{' '}
                              {instagramHashtag?.num_comments && (
                                <span>
                                  Total : {instagramHashtag?.num_comments}{' '}
                                </span>
                              )}{' '}
                            </p>
                          )}
                      </>
                    )}
                </>
              )}
              {socialMedia !== SOCIAL_MEDIA_TYPE.instagram &&
                socialMedia !== SOCIAL_MEDIA_TYPE.tiktok && (
                  <>
                    {/* <p className="font-medium text-md">Data available:</p> */}
                    {dateRange?.posts_oldest_date &&
                      dateRange?.posts_latest_date && (
                        <p className="text-sm">
                          {' '}
                          Posts{' '}
                          <span className="text-secondary font-medium">
                            {formatDate(dateRange?.posts_oldest_date)}{' '}
                          </span>
                          -{' '}
                          <span className="text-secondary font-medium">
                            {formatDate(dateRange?.posts_latest_date)}
                          </span>
                          .{' '}
                          {dateRange?.num_posts && (
                            <span>Total : {dateRange?.num_posts} </span>
                          )}{' '}
                        </p>
                      )}
                    {dateRange?.comments_oldest_date &&
                      dateRange?.comments_latest_date && (
                        <p className="text-sm">
                          {' '}
                          Comments{' '}
                          <span className="text-secondary font-medium">
                            {' '}
                            {formatDate(dateRange?.comments_oldest_date)}{' '}
                          </span>
                          -{' '}
                          <span className="text-secondary font-medium">
                            {formatDate(dateRange?.comments_latest_date)}
                          </span>
                          .{' '}
                          {dateRange?.num_comments && (
                            <span>Total : {dateRange?.num_comments} </span>
                          )}{' '}
                        </p>
                      )}
                  </>
                )}
            </div>
          )}
      {socialMediaLoader && availableDataToggle && (
        <Loader message="Our AI is generating insights from millions of social media data" />
      )}
    </div>
  );
};

export default NumOfPostComments;
