// import SearchContainer from "../../InsightsDashboard";

import CompanyNotificationsTable from './CompanyNotificationsTable';
import PropTypes from 'prop-types';
import React from 'react';
import SearchContainer from '../../ActiveFollowers/index';
import Slider from '../../Components/Slider';

const AddEditCompanyView = ({ handlePortalClose, ...props }) => {
  return (
    <React.Fragment>
      <Slider
        title={'Add/Edit Company Info'}
        handlePortalClose={handlePortalClose}
      >
        <SearchContainer {...props} showNew={true}>
          <CompanyNotificationsTable {...props} />
        </SearchContainer>
      </Slider>
    </React.Fragment>
  );
};

AddEditCompanyView.propTypes = {
  data: PropTypes.object,
  handlePortalClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default AddEditCompanyView;
