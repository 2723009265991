import PropTypes from 'prop-types';
import React from 'react';

const DataList = ({ dataItem, handleChannelClick, currentSelection }) => {
  console.log(dataItem, handleChannelClick, currentSelection, 'dataItem');
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleChannelClick(dataItem);
      }}
      title={dataItem?.label}
      className={`flex py-1 justify-between border-b hover:bg-surfacevariant hover:bg-opacity-30 text-sm hover:text-onsurfacevariant ${
        dataItem?.value === currentSelection &&
        'font-semibold bg-primarycontainer bg-opacity-50 text-onprimarycontainer'
      }`}
    >
      <div className="flex align-center pointer-events-none">
        {dataItem?.thumbnail ? (
          <img
            className="w-6 h-6 rounded-full ml-2 pointer-events-none text-xs"
            src={dataItem?.thumbnail}
            alt=""
          />
        ) : null}
        {dataItem?.label ? <div className="ml-2">{dataItem?.label}</div> : null}
      </div>
      {dataItem?.verified ? (
        <div className="align-center flex mr-3 material-icons text-base text-primary">
          {dataItem?.verified ? 'verified' : ''}
        </div>
      ) : null}
    </div>
  );
};

DataList.propTypes = {
  dataItem: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    verified: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    thumbnail: PropTypes.string,
  }).isRequired,
  handleChannelClick: PropTypes.func.isRequired,
  currentSelection: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default React.memo(DataList);
