import {
  handleEngagementEntriesData,
  handleStatsData,
} from '../../common/apiHelper';

import { SOCIAL_MEDIA_TYPE } from '../../common/constants';
import { useEffect } from 'react';
import useReactQueryCustomHook from './useStateSearchQuery.customHook';

let socialmedia = '';
// TODO:: check the selectedConnector and isActionableInsight
const useStatsSearchCustomHook = ({
  searchObject,
  type,
  interval,
  ig_type,
  tiktok_type,
  isEngagementEntriesChart,
  facebookSubTab,
  endDate,
  startDate,
  post_id,
  data_type,
  gender_type,
  content_type,
  audience_category,
  country,
  city,
}) => {
  console.log(country, 'gender_typegender_type');

  const socialMediaALL = [];
  if (searchObject.twitter) {
    socialmedia = SOCIAL_MEDIA_TYPE.twitter;
    socialMediaALL.push(socialmedia);
  }
  if (searchObject.facebook) {
    socialmedia = SOCIAL_MEDIA_TYPE.facebook;
    socialMediaALL.push(socialmedia);
  }
  if (searchObject.reddit) {
    socialmedia = SOCIAL_MEDIA_TYPE.reddit;
    socialMediaALL.push(socialmedia);
  }
  if (searchObject.articles) {
    socialmedia = SOCIAL_MEDIA_TYPE.articles;
    socialMediaALL.push(socialmedia);
  }
  if (searchObject.instagram) {
    socialmedia = SOCIAL_MEDIA_TYPE.instagram;
    socialMediaALL.push(socialmedia);
  }
  if (searchObject.youtube) {
    socialmedia = SOCIAL_MEDIA_TYPE.youtube;
    socialMediaALL.push(socialmedia);
  }
  if (searchObject.tiktok) {
    socialmedia = SOCIAL_MEDIA_TYPE.tiktok;
    socialMediaALL.push(socialmedia);
  }
  if (searchObject.linkedin) {
    socialmedia = SOCIAL_MEDIA_TYPE.linkedin;
    socialMediaALL.push(socialmedia);
  }
  if (searchObject.linkedinPublicPage) {
    socialmedia = SOCIAL_MEDIA_TYPE.linkedinPublicPage;
    socialMediaALL.push(socialmedia);
  }

  let resAll = {};
  const performApiCalls = async (searchObject, socialMedia) => {
    // if (!searchObject.days && (!startDate || !endDate)) {
    //   // Handle the case where days is not defined and either startDate or endDate is undefined
    //   return {};
    // }

    if (searchObject.twitter && socialMedia === SOCIAL_MEDIA_TYPE.twitter) {
      const resp = await handleStatsData({
        socialMediaType: SOCIAL_MEDIA_TYPE.twitter,
        handle: searchObject.twitter,
        days: searchObject.days,
        lang: searchObject.lang,
        post_id,
        gender_type,
        content_type,
        audience_category,

        type,
        interval,
        wordsToMatch: searchObject.wordsToMatch,
        wordsNotToMatch: searchObject.wordsNotToMatch,
        start_date: startDate,
        end_date: endDate,
        data_type: data_type,
      });

      // return resp;
      resAll = { ...resAll, twitter: resp };
    }
    if (searchObject.facebook && socialMedia === SOCIAL_MEDIA_TYPE.facebook) {
      const resp = await handleStatsData({
        socialMediaType: SOCIAL_MEDIA_TYPE.facebook,
        facebook_keyword: searchObject.facebook_keyword || undefined,
        handle: searchObject.facebook,
        days: searchObject.days,
        lang: searchObject.lang,
        post_id,
        gender_type,
        content_type,
        audience_category,

        type,
        interval,
        wordsToMatch: searchObject.wordsToMatch,
        wordsNotToMatch: searchObject.wordsNotToMatch,
        start_date: startDate,
        end_date: endDate,
        data_type: data_type,
      });

      resAll = { ...resAll, facebook: resp };
    }
    if (searchObject.reddit && socialMedia === SOCIAL_MEDIA_TYPE.reddit) {
      const resp = await handleStatsData({
        socialMediaType: SOCIAL_MEDIA_TYPE.reddit,
        handle: searchObject.reddit,
        days: searchObject.days,
        lang: searchObject.lang,
        post_id,
        gender_type,
        content_type,
        audience_category,

        type,
        interval,
        wordsToMatch: searchObject.wordsToMatch,
        wordsNotToMatch: searchObject.wordsNotToMatch,
        start_date: startDate,
        end_date: endDate,
        data_type: data_type,
      });
      resAll = { ...resAll, reddit: resp };
    }
    if (searchObject.articles && socialMedia === SOCIAL_MEDIA_TYPE.articles) {
      const resp = await handleStatsData({
        socialMediaType: SOCIAL_MEDIA_TYPE.articles,
        handle: searchObject.articles,
        days: searchObject.days,
        lang: searchObject.lang,
        post_id,
        gender_type,
        content_type,
        audience_category,

        type,
        interval,
        country: searchObject.country || [],
        wordsToMatch: searchObject.wordsToMatch,
        wordsNotToMatch: searchObject.wordsNotToMatch,
        start_date: startDate,
        end_date: endDate,
        data_type: data_type,
      });
      resAll = { ...resAll, articles: resp };
    }
    if (
      searchObject.instagram &&
      ig_type &&
      socialMedia === SOCIAL_MEDIA_TYPE.instagram
    ) {
      let resp = {};
      if (isEngagementEntriesChart) {
        resp = await handleEngagementEntriesData({
          socialMediaType: SOCIAL_MEDIA_TYPE.instagram,
          handle: searchObject.instagram,
          days: searchObject.days,
          lang: searchObject.lang,
          post_id,
          gender_type,
          content_type,
          audience_category,

          interval,
          ig_type,
          type,
          wordsToMatch: searchObject.wordsToMatch,
          wordsNotToMatch: searchObject.wordsNotToMatch,
          start_date: startDate,
          end_date: endDate,
          data_type: data_type,
        });
      } else {
        resp = await handleStatsData({
          socialMediaType: SOCIAL_MEDIA_TYPE.instagram,
          handle: searchObject.instagram,
          days: searchObject.days,
          post_id,
          gender_type,
          content_type,
          audience_category,

          lang: searchObject.lang,
          type,
          interval,
          ig_type,
          wordsToMatch: searchObject.wordsToMatch,
          wordsNotToMatch: searchObject.wordsNotToMatch,
          start_date: startDate,
          end_date: endDate,
          data_type: data_type,
          instagram_search_for_user_post:
            searchObject?.instagram_search_for_user_post,
          instagram_search_for_tag_post:
            searchObject?.instagram_search_for_tag_post,
          instagram_search_for_hashtag_post:
            searchObject?.instagram_search_for_hashtag_post,
        });
      }
      resAll = { ...resAll, instagram: resp };
    }
    if (searchObject.youtube && socialMedia === SOCIAL_MEDIA_TYPE.youtube) {
      const resp = await handleStatsData({
        socialMediaType: SOCIAL_MEDIA_TYPE.youtube,
        handle: searchObject.youtube,
        days: searchObject.days,
        lang: searchObject.lang,
        post_id,
        gender_type,
        content_type,
        audience_category,

        type,
        interval,
        option: searchObject.option,
        wordsToMatch: searchObject.wordsToMatch,
        wordsNotToMatch: searchObject.wordsNotToMatch,
        start_date: startDate,
        end_date: endDate,
        data_type: data_type,
      });
      resAll = { ...resAll, youtube: resp };
    }
    if (
      searchObject.tiktok &&
      tiktok_type &&
      socialMedia === SOCIAL_MEDIA_TYPE.tiktok
    ) {
      const resp = await handleStatsData({
        socialMediaType: SOCIAL_MEDIA_TYPE.tiktok,
        handle: searchObject.tiktok,
        days: searchObject.days,
        lang: searchObject.lang,
        type,
        post_id,
        gender_type,
        content_type,
        audience_category,
        country,
        city,
        interval,
        tiktok_type,
        wordsToMatch: searchObject.wordsToMatch,
        wordsNotToMatch: searchObject.wordsNotToMatch,
        start_date: startDate,
        end_date: endDate,
        data_type: data_type,
        tiktok_search_for_user_post: searchObject.tiktok_search_for_user_post,
        tiktok_search_for_keyword_post:
          searchObject.tiktok_search_for_keyword_post,
        tiktok_search_for_hashtag_post:
          searchObject.tiktok_search_for_hashtag_post,
      });
      resAll = { ...resAll, tiktok: resp };
    }
    return resAll;
  };

  // const APICallMethod = useCallback(performApiCalls, []);

  // const { data, isError, isFetching, isLoading, refetch, isSuccess } =
  //   useReactQueryCustomHook(
  //     [
  //       'searchTerm',
  //       type,
  //       interval,
  //       socialmedia,
  //       searchObject.days,
  //       searchObject[socialmedia],
  //       ig_type,
  //       tiktok_type,
  //       searchObject.option,
  //       facebookSubTab,
  //       startDate,
  //       endDate,
  //       post_id,
  //       searchObject.wordsToMatch,
  //       searchObject.wordsNotToMatch,
  //       data_type,
  //     ],
  //     () => performApiCalls(searchObject),
  //   );

  function usePlatformData(platform) {
    const { data, isError, isFetching, isLoading, refetch, isSuccess } =
      useReactQueryCustomHook(
        [
          'searchTerm',
          type,
          interval,
          platform,
          searchObject.days,
          searchObject[platform],
          ig_type,
          tiktok_type,
          searchObject.option,
          facebookSubTab,
          startDate,
          endDate,
          post_id,
          gender_type,
          content_type,
          audience_category,
          country,
          city,
          searchObject.wordsToMatch,
          searchObject.wordsNotToMatch,
          data_type,
          searchObject.lang,
        ],
        () => performApiCalls(searchObject, platform),
      );

    return { data, isError, isFetching, isLoading, refetch, isSuccess };
  }

  const socialMediaPlatforms = [
    'instagram',
    'tiktok',
    'facebook',
    'twitter',
    'youtube',
    'articles',
    'reddit',
  ]; // Add or remove platforms as needed

  let data;
  let isError,
    isFetching,
    isLoading = true,
    refetch,
    isSuccess;

  socialMediaPlatforms.map((socialMedia, index) => {
    const res = usePlatformData(socialMedia);
    data = {
      ...data,
      [socialMedia]: res?.data?.[socialMedia] || undefined,
    };
    isError = res?.isError;
    isFetching = res?.isFetching;
    if (
      (searchObject.tiktok && data?.tiktok) ||
      (searchObject.instagram && data?.instagram) ||
      (searchObject.twitter && data?.twitter) ||
      (searchObject.facebook && data?.facebook) ||
      (searchObject.reddit && data?.reddit) ||
      (searchObject.articles && data?.articles) ||
      (searchObject.youtube && data?.youtube)
    ) {
      isLoading = res?.isLoading;
    }
    isSuccess = res?.isSuccess;
  });

  useEffect(() => {
    return () => {};
  }, []);

  const handleAllApi = () => {
    // refetch();
  };

  return {
    twitterResponse: searchObject.twitter ? (data ? data : {}) : '',
    twitterLoader: isLoading || isFetching,
    twitterError: isError,

    facebookResponse: searchObject.facebook ? (data ? data : {}) : '',
    facebookLoader: isLoading || isFetching,
    facebookError: isError,

    instagramResponse: searchObject.instagram ? (data ? data : {}) : '',
    instagramLoader: isLoading || isFetching,
    instagramError: isError,

    redditResponse: searchObject.reddit ? (data ? data : {}) : '',
    redditLoader: isLoading || isFetching,
    redditError: isError,

    articlesResponse: searchObject.articles ? (data ? data : {}) : '',
    articlesLoader: isLoading || isFetching,
    articlesError: isError,

    youtubeResponse: searchObject.youtube ? (data ? data : {}) : '',
    youtubeLoader: isLoading || isFetching,
    youtubeError: isError,

    tiktokResponse: searchObject.tiktok ? (data ? data : {}) : '',
    tiktokLoader: isLoading || isFetching,
    tiktokError: isError,

    socialMediaResponse: {
      twitter: searchObject.twitter ? (data?.twitter ? data?.twitter : {}) : '',
      facebook: searchObject.facebook
        ? data?.facebook
          ? data?.facebook
          : {}
        : '',
      instagram: searchObject.instagram
        ? data?.instagram
          ? data?.instagram
          : {}
        : '',
      reddit: searchObject.reddit ? (data?.reddit ? data?.reddit : {}) : '',
      articles: searchObject.articles
        ? data?.articles
          ? data?.articles
          : {}
        : '',
      youtube: searchObject.youtube ? (data?.youtube ? data?.youtube : {}) : '',
      tiktok: searchObject.tiktok ? (data?.tiktok ? data?.tiktok : {}) : '',
    },
    socialMediaLoader: isLoading || isFetching,
    socialMediaError: isError,
    handleAllApi,
  };
};

export default useStatsSearchCustomHook;
