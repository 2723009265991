import React, { useEffect, useState } from 'react';

import { CiCircleInfo } from 'react-icons/ci';
import SelectFilter from '../../SelectFilter/SelectFilter';
import SelectFilterFilter from '../../SelectFilter/SelectFilterFilter';
import Tooltip from '../../../common/Tooltip/Tooltip';

const IntervalSelector = ({
  handleIntervalType,
  selectedOption,
  onIntervalChange,
}) => {
  // If necessary, you can initialize internal state here, but currently it's not needed
  const [currentSelection, setCurrentSelection] = useState(selectedOption);

  useEffect(() => {
    // No need to use setLanguageGender or anything, just rely on selectedOption
    if (selectedOption) {
      // Option validation (just in case the selectedOption is invalid)
      const selectedOptionObj = options.find(
        (option) => option.value === selectedOption,
      );

      if (selectedOptionObj) {
        setCurrentSelection(selectedOptionObj); // Update state with the selected option
      } else {
        setCurrentSelection({ value: 'all', label: 'All' }); // Fallback to default
      }
    }
  }, [selectedOption]);

  const options = [
    { value: 'day', label: 'Daily' },
    { value: 'week', label: 'Weekly' },
    { value: 'month', label: 'Monthly' },
  ];

  return (
    <div className="flex">
      {/* <div className=" py-2 pr-2 flex justify-start items-end">
        <Tooltip text="Analyse data by Days/Week/Month" align="start">
          <div className="cursor-pointer relative">
            <CiCircleInfo className="text-[20px] font-bold text-green-700" />
          </div>
        </Tooltip>
      </div> */}
      <SelectFilterFilter
        value={selectedOption} // This remains the controlled value
        onValueChange={(value) => {
          // Pass the updated value back to the parent component
          handleIntervalType(value);
          setCurrentSelection(options.find((option) => option.value === value)); // Update local state as well
        }}
        placeholder="View Results by"
        options={options}
        helpText="You can select Day/Week/Month for analyzing the data view."
      />
    </div>
  );
};

export default IntervalSelector;
