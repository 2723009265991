import {
  CUSTOM_RANGE,
  DAYS,
  SAVE_LOGIN_TOKEN_CHANNEL,
} from '../../../common/constants';
import { DIALOG_TYPE, LANGUAGES_METADATA } from '../../../common/metadata';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  generateMediChannelSearchTerm,
  getSocialMediaChannel,
} from '../../../common/utils';
import {
  getSearchObject,
  getSearchObjectEdit,
} from '../ResultsView/Insights/Transformer.Insights';
import { useLocation, useNavigate } from 'react-router-dom';

import AccordionComponent from '../../Components/ComponentShadcn/AccordionComponent';
import BooleanSearch from '../../Components/BooleanSearch';
import { CiCircleInfo } from 'react-icons/ci';
import Dialog from '../../../common/Dialog';
import { HeaderContext } from '../../App/LayoutComponents/header/HeaderContext';
import InputWithLabel from '../../SelectFilter/InputWithLabel';
import IntervalSelector from './IntervalSelection';
import MediaWell from './MediaWell';
import MediaWellPortfolio from './MediaWellPortfolio';
import PropTypes from 'prop-types';
import SelectFilter from '../../SelectFilter/SelectFilter';
import SelectFilterFilter from '../../SelectFilter/SelectFilterFilter';
import Tooltip from '../../../common/Tooltip/Tooltip';
import { UserProfileContext } from '../../../store/context/profile.context';
import dayjs from 'dayjs';
import useCompaniesCustomHook from '../../customHooks/useCompanies.customHook';
import { useToast } from '../../App/LayoutComponents/TosterUi/ToastProviderUi';

const SearchContainer = ({
  data,
  handleSubmit,
  saveCompaniesOperationType,
  children,
  isEdit = false,
  showNew,
  isgptpage = false,
  gptUpdatedSearchObject = () => {},
}) => {
  const [modalShow, setmodalShow] = useState(false);
  const location = useLocation();
  const profileContext = useContext(UserProfileContext);
  const navigate = useNavigate();
  const isTrackcompaniesViewLocation = location.pathname.startsWith(
    '//portfolio/trackcompanies',
  );
  const isProfileViewLocation = location.pathname.startsWith('/profile');

  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [groupName, setGroupName] = useState('');
  const [socialMediaSearchValues, setSocialMediaSearchValues] = useState(
    generateMediChannelSearchTerm(location.pathname),
  );
  const [startdate, setStartDate] = useState(
    dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
  );
  const { showToast } = useToast();
  const [enddate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [dateselectiontype, setDatetype] = useState(DAYS);
  const [language, setLanguage] = useState('en');
  const [days, setDays] = useState(2);
  const [youtubeOption, setYoutubeOption] = useState(false);
  const [instagramOption, setInstagramOption] = useState(false);
  const [tiktokOption, setTiktokOption] = useState(false);
  const [instagramType, setInstagramType] = useState(false);
  const [tiktokType, setTiktokType] = useState(false);
  const [youtubeType, setYoutubeType] = useState(false);
  const [twitterOption, setTwitterOption] = useState(false);
  const [facebookOption, setFacebookOption] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(() =>
    generateMediChannelSearchTerm(location.pathname),
  );
  const [socialMediaChannels, setSocialMediaChannels] = useState(
    getSocialMediaChannel(location.pathname),
  );

  const [showSearchLimitDialog, setShowSearchLimitDialog] = useState(false);
  const userCurrentLimit =
    profileContext?.profileInfo?.liveSearchesSinceAccountCreation;
  const MaxSearchLimit = profileContext?.profileInfo?.SocialListening?.limit;

  const { addCompany } = useCompaniesCustomHook();

  const [selectedOption, setSelectedOption] = useState('day');
  const [autoSelect, setAutoSelect] = useState(true);

  console.log(showNew, 'instagramType');

  const handleIntervalType = (value) => {
    setAutoSelect(false);
    // e.preventDefault();
    console.log(value);
    setSelectedOption(value);
  };
  const { setTitleHeader } = useContext(HeaderContext);

  useEffect(() => {
    setTitleHeader('Social Insights Dashboard');
    return () => {
      setTitleHeader(''); // Cleanup on unmount
    };
  }, [setTitleHeader]);
  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code && queryParams.get('state') === SAVE_LOGIN_TOKEN_CHANNEL.tiktok) {
      navigate('/profile', {
        state: { code, socialMediaChannels: SAVE_LOGIN_TOKEN_CHANNEL.tiktok },
      });
    }
  }, []);

  // useEffect for save and edit companies code
  useEffect(() => {
    if (data) {
      debugger;
      setSearchBoxValue(data.masterSearch);
      setSocialMediaSearchValues({
        twitter: data.mediaSearchKeys.twitter,
        facebook: data.mediaSearchKeys.facebook,
        instagram: data.mediaSearchKeys.instagram,
        reddit: data.mediaSearchKeys.reddit,
        news: data.mediaSearchKeys.news,
        tiktok: data.mediaSearchKeys.tiktok,
        linkedin: data.mediaSearchKeys.linkedin,
        youtube: data.mediaSearchKeys.youtube,
        trustPilotcompanyName: data.mediaSearchKeys.trustPilotcompanyName,
      });
    }
  }, [data]);

  console.log(socialMediaSearchValues, 'socialMediaSearchValues');
  console.log(socialMediaChannels, 'socialMediaChannels');

  useEffect(() => {
    debugger;
    if (data) {
      setSocialMediaChannels(
        socialMediaChannels
          .map((channel) => {
            if (
              data.mediaSearchKeys?.[channel.label.toLowerCase()] != '' &&
              data.mediaSearchKeys?.[channel.label.toLowerCase()] != undefined
            ) {
              return { ...channel, state: true };
            }
            return channel;
          })
          .sort((a, b) => Number(b.state) - Number(a.state)),
      );
    }
  }, [data]);

  const handleOptionSelect = (searchOption, socialMediaType) => {
    if (socialMediaType === 'youtube') {
      setYoutubeOption(searchOption);
    } else if (socialMediaType === 'twitter') {
      setTwitterOption(searchOption);
    } else if (socialMediaType === 'tiktok') {
      setTiktokOption(searchOption);
    } else if (socialMediaType === 'instagram') {
      setInstagramOption(searchOption);
    } else if (socialMediaType === 'facebook') {
      setFacebookOption(searchOption);
    }
  };

  const onMediaSourceToggle = (id, value) => {
    debugger;
    setSocialMediaChannels(
      socialMediaChannels
        .map((channel) => {
          if (channel.id === id) {
            return { ...channel, state: value };
          }
          return channel;
        })
        .sort((a, b) => Number(b.state) - Number(a.state)),
    );
  };

  const setMediaSearchInput = useCallback((channel, value, displayValue) => {
    debugger;
    if (channel === 'facebook') {
      setSearchCriteria((searchCriteria) => ({
        ...searchCriteria,
        [channel]: value,
        facebookDisplayName: displayValue,
      }));
    } else if (channel === 'linkedin') {
      setSearchCriteria((searchCriteria) => ({
        ...searchCriteria,
        [channel]: value,
        linkedin_publicpage_url: displayValue,
        linkedin_publicpage: value,
      }));
    } else {
      setSearchCriteria((searchCriteria) => ({
        ...searchCriteria,
        [channel]: value,
      }));
    }
  }, []);

  const handleSearchContainerRoute = (searchObject) => {
    if (location.pathname === '/influencers/activeFollowers') {
      navigate('/ActiveFollowersResultsView', { state: searchObject });
    } else if (location.pathname === '/influencers/activeInfluencers') {
      navigate('/ActiveInfluencersResultsView', { state: searchObject });
    } else if (
      location.pathname === '/socialinsights' ||
      location.pathname === '/' ||
      location.pathname === '/resultsView'
    ) {
      navigate('/resultsView', { state: searchObject });
    } else if (location.pathname === '/ActiveFollowersResultsView') {
      navigate('/ActiveFollowersResultsView', { state: searchObject });
    } else if (location.pathname === '/ActiveInfluencersResultsView') {
      navigate('/ActiveInfluencersResultsView', { state: searchObject });
    } else if (
      location.pathname === '/socialMentionsSmartInbox/mentionsTimeline'
    ) {
      navigate('/MentionsTimelineResultsView', { state: searchObject });
    }
  };

  const postSearchCriteria = () => {
    if (
      profileContext.profileInfo.liveSearchesSinceAccountCreation ===
      profileContext.profileInfo.SocialListening?.limit
    ) {
      setShowSearchLimitDialog(true);
      return;
    }
    const searchObject = getSearchObject({
      socialMediaChannels,
      isTrackcompaniesViewLocation,
      isProfileViewLocation,
      searchCriteria,
      searchBoxValue,
      twitterOption,
      tiktokOption,
      youtubeOption,
      dateselectiontype,
      days,
      startdate,
      enddate,
      wordsToMatch: wordsToBeIncludedInSearchObject || undefined,
      wordsNotToMatch: wordsToBeExcludedInSearchObject || undefined,
      language,
      instagramOption,
      facebookOption,
      socialMediaSearchValues,
      selectedOption,
      instagramType,
      tiktokType,
      youtubeType,
    });
    if (!searchObject) {
      return;
    }
    setSearchBoxValue('');
    handleSearchContainerRoute(searchObject);
  };
  console.log(data, 'jhkjhdata');

  const setGPTsearchObject = () => {
    if (
      profileContext.profileInfo.liveSearchesSinceAccountCreation ===
      profileContext.profileInfo.SocialListening?.limit
    ) {
      setShowSearchLimitDialog(true);
      return;
    }
    const searchObject = getSearchObject({
      socialMediaChannels,
      isTrackcompaniesViewLocation,
      isProfileViewLocation,
      searchCriteria,
      searchBoxValue,
      twitterOption,
      tiktokOption,
      youtubeOption,
      dateselectiontype,
      days,
      startdate,
      enddate,
      wordsToMatch: wordsToBeIncludedInSearchObject || undefined,
      wordsNotToMatch: wordsToBeExcludedInSearchObject || undefined,
      language,
      instagramOption,
      facebookOption,
      socialMediaSearchValues,
      selectedOption,
      instagramType,
      tiktokType,
      youtubeType,
    });
    if (!searchObject) {
      return;
    }
    gptUpdatedSearchObject(searchObject);
  };

  const saveCompanies = () => {
    debugger;
    const searchObject = getSearchObjectEdit({
      socialMediaChannels,
      isTrackcompaniesViewLocation,
      isProfileViewLocation,
      searchCriteria,
      searchBoxValue,
      twitterOption,
      tiktokOption,
      youtubeOption,
      dateselectiontype,
      days,
      startdate,
      enddate,
      wordsToMatch: wordsToBeIncludedInSearchObject || undefined,
      wordsNotToMatch: wordsToBeExcludedInSearchObject || undefined,
      language,
      instagramOption,
      facebookOption,
      socialMediaSearchValues,
      instagramType,
      tiktokType,
      youtubeType,
      facebookScreenName: data?.facebookScreenName,
    });
    if (searchObject) {
      if (handleSubmit && saveCompaniesOperationType) {
        handleSubmit(
          { ...searchObject, group_name: groupName || 'Other' },
          saveCompaniesOperationType,
        );
      } else {
        addCompany({
          keyword: searchObject.formData.mainSearchTerm || '',
          // trustPilotcompanyName:
          //   searchObject.channels.trustPilotcompanyName || "",
          facebook_searchname: searchObject.channels.facebook || '',
          Facebook_screen_name: searchObject.channels.facebookDisplayName || '',
          twitter_searchname: searchObject.channels.twitter || '',
          instagram_searchname: searchObject.channels.instagram || '',
          Reddit: searchObject.channels.reddit || '',
          youtube_channel: searchObject.channels.youtube || '',
          lang: searchObject.formData.lang || '',
          news_keyword: searchObject.channels.news,
          tiktok_searchname: searchObject.channels.tiktok,
          linkedin_publicpage_url:
            searchObject.channels.linkedin_publicpage_url,
          linkedin_publicpage: searchObject.channels.linkedin_publicpage,
          option: searchObject.formData.youtubeOption,
          tiktok_type: searchObject.formData.tiktokOption,
          ig_type: searchObject.formData.instagramOption,
          alertsData: [],
          categories: [],
          instagramType: instagramType,
          tiktokType: tiktokType,
          youtubeType: youtubeType,
          group_name: groupName || 'Other',
        });
        handleSearchContainerRoute(searchObject);
      }
    }
  };

  const [wordsToBeExcludedInSearchObject, setWordsToBeExcludedInSearchObject] =
    useState();
  const [wordsToBeIncludedInSearchObject, setWordsToBeIncludedInSearchObject] =
    useState();

  const [toggleBooleanSearch, setToggleBooleanSearch] = useState(false);
  const handleBooleanSearch = () => {
    setToggleBooleanSearch(!toggleBooleanSearch);
  };
  const handleDaysChangeShowalert = (value) => {
    const numValue = Number(value);
    setDays(numValue);
    if (numValue > 200) {
      showToast({
        message:
          'You have entered more than 200 days. Processing such a large dataset may take extra time. We appreciate your patience as the data loads.!',
        type: 'warning',
      });
    }
  };
  return (
    <React.Fragment>
      <div>
        {/* <div className="flex items-center justify-center w-full">
          <h3 className="text-center text-[20px] font-semibold text-secondary">
            Social Insights Dashboard
          </h3>
        </div> */}

        <div className="flex flex-col justify-center align-center">
          <label
            htmlFor="searchbox"
            className="text-lg text-onbackground my-3 self-center "
          >
            Stay Ahead with Real-time Insights on Social Media & Web
          </label>
          <div
            className={`rounded-md relative flex items-center min-w-[300px] mx-[15%] mb-4 bg-background shadow-lg`}
          >
            <input
              type="text"
              name="searchbox"
              defaultValue={searchBoxValue}
              onChange={(e) => {
                if (userCurrentLimit >= MaxSearchLimit) {
                  setmodalShow(true);
                  return { error: 'You have exceeded your max search limit.' };
                }
                setSearchBoxValue(e.target.value);
              }}
              className={`rounded-md border-transparent w-full py-3 px-4 appearance-none border border-outline bg-background
               text-onprimarycontainer placeholder-outline text-base focus:ring-primary`}
              placeholder="Start typing in any topic/company name"
              autoComplete="off"
            />
            <div className="absolute right-3 top-3 pointer-events-none text-md text-primary">
              <span className="material-icons">search</span>
            </div>
          </div>
        </div>

        <div
          className={`rounded-md relative flex items-center min-w-[300px] mx-[35%] mb-4 bg-background shadow-lg`}
        >
          <input
            type="text"
            name="groupname"
            defaultValue={groupName}
            onChange={(e) => {
              // setSearchBoxValue(e.target.value);
              setGroupName(e.target.value);
            }}
            className={`rounded-md border-transparent w-full py-3 px-4 appearance-none border border-outline bg-background
               text-onprimarycontainer placeholder-outline text-base focus:ring-primary`}
            placeholder="Enter group name"
            autoComplete="off"
          />
        </div>

        {!searchBoxValue && (
          <div className="flex justify-center">
            {socialMediaChannels.map((item) => (
              <div
                key={item.id}
                className={`w-16 h-16 rounded-full p-3 bg-surface mx-5 my-5`}
                title={item.label}
              >
                <img
                  src={`/icons/social-media-icons/colored/${item.icon}.svg`}
                  alt={item.label}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {searchBoxValue && (
        <React.Fragment>
          {/* <AccordionComponent id="Filters-1" triggerText="Filters"> */}
          <div
            className="rounded-[7px] bg-surface bg-opacity-50 border shadow-md border-gray-400"
            style={{
              borderColor: 'hsl(0, 0%, 80%)',
              backgroundColor: '#f2f7fa',
            }}
          >
            <div className="flex flex-wrap  items-center justify-start gap-4 px-4  py-4">
              <div>
                <SelectFilterFilter
                  value={dateselectiontype}
                  onValueChange={(value) => setDatetype(value)}
                  options={[
                    { value: DAYS, label: 'Days' },
                    { value: CUSTOM_RANGE, label: 'Custom Range' },
                  ]}
                  placeholder="Interval"
                />
              </div>
              {/* <div className="flex-col mx-3">
                  <label className="block text-md mb-1 ml-1">
                    Day Selection
                  </label>
                  <label
                    htmlFor="timeOptionsDays"
                    className="flex items-center mb-1 flex-nowrap text-sm"
                  >
                    <input
                      type="radio"
                      value={DAYS}
                      id={'timeOptionsDays'}
                      name="dateselectiontype"
                      defaultChecked
                      onChange={(e) => setDatetype(e.target.value)}
                      className="appearance-none border border-primary focus:outline-none focus:ring-0 mr-1"
                    />
                    Days
                  </label>
                  <label
                    htmlFor="timeOptionsDate"
                    className="flex items-center flex-nowrap text-sm"
                  >
                    <input
                      type="radio"
                      value={CUSTOM_RANGE}
                      id={'timeOptionsDate'}
                      name="dateselectiontype"
                      onChange={(e) => setDatetype(e.target.value)}
                      className="appearance-none border border-primary focus:outline-none focus:ring-0 mr-1"
                    />
                    Custom Range
                  </label>
                </div> */}
              <div className="flex-col">
                {dateselectiontype === DAYS && (
                  <InputWithLabel
                    label="Number of days"
                    id="Number of days"
                    type="number"
                    placeholder="Number of days"
                    value={days}
                    onChange={(e) => handleDaysChangeShowalert(e.target.value)}
                  />
                  // <React.Fragment>
                  //   <label className="block text-md mb-1 ml-1">
                  //     Number of days
                  //   </label>
                  //   <input
                  //     type="number"
                  //     name="dayspicker"
                  //     defaultValue={days}
                  //     onChange={(e) => setDays(e.target.value)}
                  //     min="1"
                  //     className={`rounded-md max-w-[20ch] bg-background text-sm appearance-none border border-primary focus:outline-none focus:ring-1 `}
                  //   />
                  // </React.Fragment>
                )}
                {dateselectiontype === CUSTOM_RANGE && (
                  <React.Fragment>
                    <div className="flex flex-wrap">
                      <div>
                        <label className="block text-xs ">Start Date</label>
                        <input
                          type="date"
                          name="startdatepicker"
                          defaultValue={startdate}
                          placeholder="Start Date"
                          onChange={(e) => setStartDate(e.target.value)}
                          className={`rounded-md max-w-[20ch] bg-background text-sm appearance-none border border-primary focus:outline-none focus:ring-1 mr-1`}
                        />
                      </div>
                      <div>
                        <label className="block text-xs">End Date</label>
                        <input
                          type="date"
                          name="enddatepicker"
                          defaultValue={enddate}
                          placeholder="End Date"
                          onChange={(e) => setEndDate(e.target.value)}
                          className={`rounded-md max-w-[20ch] bg-background text-sm appearance-none border border-primary focus:outline-none focus:ring-1 `}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className="">
                <IntervalSelector
                  handleIntervalType={handleIntervalType}
                  selectedOption={selectedOption}
                />
              </div>
            </div>

            <div className="flex-col mb-4 px-4">
              <SelectFilter
                value={language}
                onValueChange={(value) => setLanguage(value)}
                options={LANGUAGES_METADATA.map((lang) => ({
                  value: lang.value,
                  label: lang.language,
                }))}
                placeholder="Content Language"
              />
              {/* <label htmlFor="language-selector" className="block text-md">
                Content Language
              </label>
              <select
                name="language-selector"
                className="rounded-md text-sm w-[20ch] bg-background appearance-none border border-primary focus:outline-none focus:ring-1 "
                onChange={(e) => setLanguage(e.target.value)}
              >
                {LANGUAGES_METADATA.map((lang) => {
                  return (
                    <option value={lang.value} key={lang.value}>
                      {lang.language}
                    </option>
                  );
                })}
              </select> */}
            </div>
            <div className="flex mb-4 px-4">
              <button
                onClick={handleBooleanSearch}
                className="flex flex-row  whitespace-nowrap justify-center items-center"
              >
                {/* <span className="material-icons cursor-pointer">
                  filter_list
                </span> */}
                Boolean Search
                <span
                  onClick={() => setToggleBooleanSearch(!toggleBooleanSearch)}
                  className="ml-1"
                >
                  {' '}
                  {!toggleBooleanSearch ? (
                    <div className=" cursor-pointer">
                      <IoIosArrowDown />
                    </div>
                  ) : (
                    <div className=" cursor-pointer">
                      <IoIosArrowUp />
                    </div>
                  )}
                </span>
              </button>
              <Tooltip text="Included/Excluded keywords" align="start">
                <div className="cursor-pointer relative">
                  <CiCircleInfo className="text-[20px] font-bold text-green-700" />
                </div>
              </Tooltip>
            </div>
            <div className="px-4">
              {toggleBooleanSearch && (
                <BooleanSearch
                  setWordsToBeExcludedInSearchObject={
                    setWordsToBeExcludedInSearchObject
                  }
                  setWordsToBeIncludedInSearchObject={
                    setWordsToBeIncludedInSearchObject
                  }
                />
              )}
            </div>
          </div>
          {showNew ? (
            <MediaWellPortfolio
              showNew={showNew}
              masterSearchQuery={searchBoxValue}
              socialMediaChannels={socialMediaChannels}
              setMediaSearchInput={setMediaSearchInput}
              onMediaSourceToggle={onMediaSourceToggle}
              youtubeOption={youtubeOption}
              twitterOption={twitterOption}
              facebookOption={facebookOption}
              tiktokOption={tiktokOption}
              youtubeType={youtubeType}
              setTiktokType={setTiktokType}
              setInstagramType={setInstagramType}
              setYoutubeType={setYoutubeType}
              instagramOption={instagramOption}
              handleOptionSelect={handleOptionSelect}
              socialMediaSearchValues={socialMediaSearchValues}
              setSocialMediaSearchValues={setSocialMediaSearchValues}
              setSelectedCompanyid={'setSelectedCompanyid'}
              companyInfo={data}
            />
          ) : (
            <MediaWell
              masterSearchQuery={searchBoxValue}
              socialMediaChannels={socialMediaChannels}
              setMediaSearchInput={setMediaSearchInput}
              onMediaSourceToggle={onMediaSourceToggle}
              youtubeOption={youtubeOption}
              twitterOption={twitterOption}
              facebookOption={facebookOption}
              tiktokOption={tiktokOption}
              youtubeType={youtubeType}
              setTiktokType={setTiktokType}
              setInstagramType={setInstagramType}
              setYoutubeType={setYoutubeType}
              instagramOption={instagramOption}
              handleOptionSelect={handleOptionSelect}
              socialMediaSearchValues={socialMediaSearchValues}
              setSocialMediaSearchValues={setSocialMediaSearchValues}
              companyInfo={data}
              isEdit={isEdit}
            />
          )}
          {React.isValidElement(children) &&
            React.cloneElement(children, { item: data || [] })}
          <div className="flex flex-wrap gap-2 justify-center items-center mb-8 mt-4">
            {(saveCompaniesOperationType && (
              <button
                type="button"
                onClick={saveCompanies}
                className={`py-3 px-4 flex mx-1 justify-center items-center bg-primarycontainer text-onprimarycontainer transition ease-in duration-200 
        text-center text-base rounded hover:bg-primary hover:text-onprimary hover:shadow-md`}
              >
                <span className="material-icons mx-2">save</span>
                Save Company
              </button>
            )) || (
              <>
                {!isgptpage && (
                  <button
                    type="button"
                    onClick={postSearchCriteria}
                    className={`py-3 px-4 flex my-1 justify-center items-center bg-onsecondaryvariant text-onsecondary transition ease-in duration-200 
        text-center text-base rounded hover:bg-secondary hover:text-onsecondary hover:shadow-md`}
                  >
                    <span className="material-icons mx-2">travel_explore</span>
                    Fetch Insights
                  </button>
                )}

                {isgptpage && (
                  <button
                    type="button"
                    onClick={setGPTsearchObject}
                    className={`py-3 px-4 flex my-1 justify-center items-center bg-onsecondaryvariant text-onsecondary transition ease-in duration-200 
        text-center text-base rounded hover:bg-secondary hover:text-onsecondary hover:shadow-md`}
                  >
                    <span className="material-icons mx-2">travel_explore</span>
                    Intract with GPT
                  </button>
                )}
                {!isgptpage && (
                  <button
                    type="button"
                    onClick={saveCompanies}
                    className={`py-3 px-4 flex my-1 justify-center items-center bg-onsecondaryvariant text-onsecondary transition ease-in duration-200 
        text-center text-base rounded hover:bg-secondary hover:text-onsecondary hover:shadow-md`}
                  >
                    <span className="material-icons mx-2">save</span>
                    Save and Fetch Insights
                  </button>
                )}
              </>
            )}
          </div>
          {(location.pathname === '/socialinsights' ||
            location.pathname === '/' ||
            location.pathname === '/influencers/activeFollowers' ||
            location.pathname === '/influencers/activeInfluencers' ||
            location.pathname ===
              '/socialMentionsSmartInbox/mentionsTimeline') && (
            <div className="flex flex-wrap justify-center items-center mx-4 text-sm">
              {profileContext.profileInfo.liveSearchesSinceAccountCreation}/
              {profileContext.profileInfo.SocialListening?.limit} search
              completed.
            </div>
          )}
          <Dialog
            title={DIALOG_TYPE.searchContainer.limit.title(
              ((location.pathname === '/socialinsights' ||
                location.pathname === '/') &&
                'Actionable Insights') ||
                (location.pathname === '/influencers/activeFollowers' &&
                  'Active Followers') ||
                (location.pathname === '/influencers/activeInfluencers' &&
                  'Active Influencers') ||
                (location.pathname ===
                  '/socialMentionsSmartInbox/mentionsTimeline' &&
                  'Mentions Timeline') ||
                ((isTrackcompaniesViewLocation || isProfileViewLocation) &&
                  'Portfolio'),
            )}
            body={DIALOG_TYPE.searchContainer.limit.body}
            positiveButton1={{
              text: DIALOG_TYPE.searchContainer.limit.positiveButtonText,
              callback: () => {
                setShowSearchLimitDialog(false);
              },
            }}
            showDialog={showSearchLimitDialog}
            setShowDialog={setShowSearchLimitDialog}
          />
          {/* </AccordionComponent> */}
        </React.Fragment>
      )}

      {modalShow && (
        <Dialog
          title="Warning!"
          showDialog={modalShow}
          setShowDialog={setmodalShow}
          body={'You have exceeded your max search limit.'}
          positiveButton1={{
            callback: () => setmodalShow(false),
            text: 'Close',
          }}
        />
      )}
    </React.Fragment>
  );
};

SearchContainer.propTypes = {
  data: PropTypes.shape({
    language: PropTypes.string,
    masterSearch: PropTypes.string,
    mediaSearchKeys: PropTypes.shape({
      twitter: PropTypes.string,
      facebook: PropTypes.string,
      instagram: PropTypes.string,
      reddit: PropTypes.string,
      news: PropTypes.string,
      tiktok: PropTypes.string,
      linkedin: PropTypes.string,
      youtube: PropTypes.string,
    }),
  }),
  handleSubmit: PropTypes.func,
  saveCompaniesOperationType: PropTypes.string,
  children: PropTypes.any,
};

export default SearchContainer;
