import {
  INSTAGRAM_TYPE,
  INTERVAL,
  SOCIAL_MEDIA_TYPE,
  TIKTOK_TYPE,
} from '../../../common/constants';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import React, { useEffect, useState } from 'react';

import { ChevronLeftIcon } from '@radix-ui/react-icons';
import Loader from '../../App/LayoutComponents/Loader/Loader';
import useStatsSearchCustomHook from '../../customHooks/useStatsSearch.customHook';

const NumOfPostCommentsNew = ({
  mainSearchTerm,
  socialMedia,
  handleFlipToggle = () => {},
}) => {
  console.log(mainSearchTerm, socialMedia, 'llklklk');

  const [dateRange, setDateRange] = useState('');
  const [availableDataToggle, setAvailableDataToggle] = useState(false);
  const [tiktoksubtab, setTiktoksubtab] = useState(0);

  const [instagramsubtab, setInstagramsubtab] = useState(0);

  const [availableDataToggleUserAcc, setAvailableDataToggleUserAcc] =
    useState(false);
  const [availableDataToggleUserKeyword, setAvailableDataToggleUserKeyword] =
    useState(false);
  const [availableDataToggleUserHashtag, setAvailableDataToggleUserHashtag] =
    useState(false);

  const [availableDataToggleUserusertag, setAvailableDataToggleUserusertag] =
    useState(false);

  const [tiktokUserData, setTiktokUserData] = useState('');
  const [tiktokkeyworddata, settiktokkeyworddata] = useState('');
  const [tiktokHashtag, settiktokHashtag] = useState('');

  const [instagramUserData, setInstagramUserData] = useState('');

  const [instagramUsertagData, setIstagramUsertagData] = useState('');

  const [instagramHashtag, setinstagramHashtagData] = useState('');

  const {
    socialMediaResponse,
    socialMediaLoader,
    socialMediaError,
    handleAllApi,
  } = useStatsSearchCustomHook({
    searchObject: {
      [socialMedia]: mainSearchTerm,
      days: 2,
      lang: 'en',
      option: undefined,
      country: undefined,
      wordsToMatch: undefined,
      wordsNotToMatch: undefined,
    },
    // post_id: seletedID,
    endDate: undefined,
    startDate: undefined,
    interval: INTERVAL.total,
    ig_type: INSTAGRAM_TYPE[instagramsubtab] || 'manual_users',
    tiktok_type: TIKTOK_TYPE[tiktoksubtab] || 'userposts',
    type: 'daterangeofpostsandcomment',
  });

  useEffect(() => {
    const data =
      socialMediaResponse[socialMedia]?.data?.stats?.[socialMedia]
        ?.timelineStats?.timeline;
    setDateRange(data);

    if (socialMedia == SOCIAL_MEDIA_TYPE.tiktok && tiktoksubtab == 0) {
      setTiktokUserData(data);
      if (!socialMediaLoader) {
        setTiktoksubtab(1);
      }
    }
    if (socialMedia == SOCIAL_MEDIA_TYPE.tiktok && tiktoksubtab == 1) {
      settiktokHashtag(data);
      if (!socialMediaLoader) {
        setTiktoksubtab(2);
      }
    }
    if (socialMedia == SOCIAL_MEDIA_TYPE.tiktok && tiktoksubtab == 2) {
      settiktokkeyworddata(data);
    }

    if (socialMedia == SOCIAL_MEDIA_TYPE.instagram && instagramsubtab == 0) {
      setInstagramUserData(data);
      if (!socialMediaLoader) {
        setInstagramsubtab(1);
      }
    }
    if (socialMedia == SOCIAL_MEDIA_TYPE.instagram && instagramsubtab == 1) {
      setIstagramUsertagData(data);
      if (!socialMediaLoader) {
        setInstagramsubtab(2);
      }
    }
    if (socialMedia == SOCIAL_MEDIA_TYPE.instagram && instagramsubtab == 2) {
      setinstagramHashtagData(data);
    }
  }, [socialMediaResponse]);

  function formatDate(dateString) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Parse the given date string
    const date = new Date(dateString);

    // Get the month, day, and year
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date
    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
  }
  return (
    <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-2 h-[400px] overflow-auto py-2">
      <button onClick={handleFlipToggle} className="flex items-center">
        <ChevronLeftIcon className="" /> <span>Back</span>
      </button>
      <div className="flex gap-1 mb-2 px-2 items-center">
        <div class=" text text-center font-bold text-lg">Available Data</div>
        <span onClick={() => setAvailableDataToggle(!availableDataToggle)}>
          {' '}
          {!availableDataToggle ? (
            <div className=" cursor-pointer">
              <IoIosArrowDown />
            </div>
          ) : (
            <div className=" cursor-pointer">
              <IoIosArrowUp />
            </div>
          )}
        </span>
      </div>
      {!availableDataToggle
        ? // <Loader message="Our AI is generating insights from millions of social media data" />
          null
        : availableDataToggle && (
            <div className="ml-2">
              {socialMedia == SOCIAL_MEDIA_TYPE.tiktok && (
                <>
                  {tiktokUserData && (
                    <div className="flex gap-1 mb-2   items-center">
                      <label className=" text text-center font-bold text ">
                        User Account
                      </label>
                      <span
                        onClick={() => {
                          setAvailableDataToggleUserAcc(
                            !availableDataToggleUserAcc,
                          );
                        }}
                      >
                        {' '}
                        {!availableDataToggleUserAcc ? (
                          <div
                            onClick={() => {
                              setTiktoksubtab(0);
                              // setAvailableDataToggleUserHashtag(false);
                              // setAvailableDataToggleUserKeyword(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}

                  {availableDataToggleUserAcc &&
                    socialMedia == SOCIAL_MEDIA_TYPE.tiktok && (
                      <>
                        {tiktokUserData?.posts_oldest_date &&
                          tiktokUserData?.posts_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Posts</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    tiktokUserData?.posts_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    tiktokUserData?.posts_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Posts */}
                                {tiktokUserData?.num_posts && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {tiktokUserData?.num_posts}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        {tiktokUserData?.comments_oldest_date &&
                          tiktokUserData?.comments_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Comments</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    tiktokUserData?.comments_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    tiktokUserData?.comments_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Comments */}
                                {tiktokUserData?.num_comments && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {tiktokUserData?.num_comments}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                      </>
                    )}

                  {tiktokkeyworddata && (
                    <div className="flex gap-1 mb-2  items-center">
                      <label className=" text text-center font-bold text ">
                        Keyword
                      </label>

                      <span
                        onClick={() => {
                          setAvailableDataToggleUserKeyword(
                            !availableDataToggleUserKeyword,
                          );
                          // setTiktoksubtab(1);
                        }}
                      >
                        {' '}
                        {!availableDataToggleUserKeyword ? (
                          <div
                            onClick={() => {
                              setTiktoksubtab(1);
                              // setAvailableDataToggleUserHashtag(false);
                              // setAvailableDataToggleUserAcc(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}
                  {availableDataToggleUserKeyword &&
                    socialMedia == SOCIAL_MEDIA_TYPE.tiktok && (
                      <>
                        {tiktokkeyworddata?.posts_oldest_date &&
                          tiktokkeyworddata?.posts_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Posts</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    tiktokkeyworddata?.posts_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    tiktokkeyworddata?.posts_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Posts */}
                                {tiktokkeyworddata?.num_posts && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {tiktokkeyworddata?.num_posts}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        {tiktokkeyworddata?.comments_oldest_date &&
                          tiktokkeyworddata?.comments_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Comments</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    tiktokkeyworddata?.comments_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    tiktokkeyworddata?.comments_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Comments */}
                                {tiktokkeyworddata?.num_comments && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {tiktokkeyworddata?.num_comments}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  {tiktokHashtag && (
                    <div className="flex gap-1 mb-2  items-center">
                      <label className=" text text-center font-bold text ">
                        Hashtag
                      </label>

                      <span
                        onClick={() => {
                          setAvailableDataToggleUserHashtag(
                            !availableDataToggleUserHashtag,
                          );
                          // setTiktoksubtab(2);
                        }}
                      >
                        {' '}
                        {!availableDataToggleUserHashtag ? (
                          <div
                            onClick={() => {
                              setTiktoksubtab(2);
                              // setAvailableDataToggleUserKeyword(false);
                              // setAvailableDataToggleUserAcc(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}
                  {availableDataToggleUserHashtag &&
                    socialMedia == SOCIAL_MEDIA_TYPE.tiktok && (
                      <>
                        {tiktokHashtag?.posts_oldest_date &&
                          tiktokHashtag?.posts_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Posts</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(tiktokHashtag?.posts_oldest_date)}{' '}
                                  -{' '}
                                  {formatDate(tiktokHashtag?.posts_latest_date)}
                                </span>
                                <br />

                                {/* Total Posts */}
                                {tiktokHashtag?.num_posts && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {tiktokHashtag?.num_posts}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        {tiktokHashtag?.comments_oldest_date &&
                          tiktokHashtag?.comments_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Comments</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    tiktokHashtag?.comments_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    tiktokHashtag?.comments_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Comments */}
                                {tiktokHashtag?.num_comments && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {tiktokHashtag?.num_comments}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                      </>
                    )}
                </>
              )}

              {socialMedia == SOCIAL_MEDIA_TYPE.instagram && (
                <>
                  {instagramUserData && (
                    <div className="flex gap-1 mb-2  items-center">
                      <label className=" text text-center font-bold text ">
                        User Account
                      </label>

                      <span
                        onClick={() =>
                          setAvailableDataToggleUserAcc(
                            !availableDataToggleUserAcc,
                          )
                        }
                      >
                        {' '}
                        {!availableDataToggleUserAcc ? (
                          <div
                            onClick={() => {
                              setInstagramsubtab(0);
                              // setAvailableDataToggleUserusertag(false);
                              // setAvailableDataToggleUserHashtag(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}

                  {availableDataToggleUserAcc &&
                    socialMedia == SOCIAL_MEDIA_TYPE.instagram && (
                      <>
                        {instagramUserData?.posts_oldest_date &&
                          instagramUserData?.posts_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Posts</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    instagramUserData?.posts_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    instagramUserData?.posts_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Posts */}
                                {instagramUserData?.num_posts && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {instagramUserData?.num_posts}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        {instagramUserData?.comments_oldest_date &&
                          instagramUserData?.comments_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Comments</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    instagramUserData?.comments_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    instagramUserData?.comments_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Comments */}
                                {instagramUserData?.num_comments && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {instagramUserData?.num_comments}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  {instagramUsertagData && (
                    <div className="flex gap-1 mb-2  items-center">
                      <label className=" text text-center font-bold text ">
                        User Tag
                      </label>

                      <span
                        onClick={() =>
                          setAvailableDataToggleUserusertag(
                            !availableDataToggleUserusertag,
                          )
                        }
                      >
                        {' '}
                        {!availableDataToggleUserusertag ? (
                          <div
                            onClick={() => {
                              setInstagramsubtab(1);
                              // setAvailableDataToggleUserHashtag(false);
                              // setAvailableDataToggleUserAcc(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}

                  {availableDataToggleUserusertag &&
                    socialMedia == SOCIAL_MEDIA_TYPE.instagram && (
                      <>
                        {instagramUsertagData?.posts_oldest_date &&
                          instagramUsertagData?.posts_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Posts</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    instagramUsertagData?.posts_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    instagramUsertagData?.posts_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Posts */}
                                {instagramUsertagData?.num_posts && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {instagramUsertagData?.num_posts}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        {instagramUsertagData?.comments_oldest_date &&
                          instagramUsertagData?.comments_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Comments</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    instagramUsertagData?.comments_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    instagramUsertagData?.comments_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Comments */}
                                {instagramUsertagData?.num_comments && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {instagramUsertagData?.num_comments}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  {instagramHashtag && (
                    <div className="flex gap-1 mb-2  items-center">
                      <label className=" text text-center font-bold text ">
                        Hashtag
                      </label>

                      <span
                        onClick={() =>
                          setAvailableDataToggleUserHashtag(
                            !availableDataToggleUserHashtag,
                          )
                        }
                      >
                        {' '}
                        {!availableDataToggleUserHashtag ? (
                          <div
                            onClick={() => {
                              setInstagramsubtab(2);
                              // setAvailableDataToggleUserusertag(false);
                              // setAvailableDataToggleUserAcc(false);
                            }}
                            className=" cursor-pointer"
                          >
                            <IoIosArrowDown />
                          </div>
                        ) : (
                          <div className=" cursor-pointer">
                            <IoIosArrowUp />
                          </div>
                        )}
                      </span>
                    </div>
                  )}

                  {availableDataToggleUserHashtag &&
                    socialMedia == SOCIAL_MEDIA_TYPE.instagram && (
                      <>
                        {instagramHashtag?.posts_oldest_date &&
                          instagramHashtag?.posts_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Posts</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    instagramHashtag?.posts_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    instagramHashtag?.posts_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Posts */}
                                {instagramHashtag?.num_posts && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {instagramHashtag?.num_posts}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        {instagramHashtag?.comments_oldest_date &&
                          instagramHashtag?.comments_latest_date && (
                            <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                              <h3 className="text font-semibold">Comments</h3>

                              <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                                <span className="font-bold text-gray-700">
                                  {formatDate(
                                    instagramHashtag?.comments_oldest_date,
                                  )}{' '}
                                  -{' '}
                                  {formatDate(
                                    instagramHashtag?.comments_latest_date,
                                  )}
                                </span>
                                <br />

                                {/* Total Comments */}
                                {instagramHashtag?.num_comments && (
                                  <span className="text-gray-500">
                                    Total:{' '}
                                    <span className="text-blue-500">
                                      {instagramHashtag?.num_comments}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                      </>
                    )}
                </>
              )}
              {socialMedia !== SOCIAL_MEDIA_TYPE.instagram &&
                socialMedia !== SOCIAL_MEDIA_TYPE.tiktok && (
                  <>
                    {dateRange?.posts_oldest_date &&
                      dateRange?.posts_latest_date && (
                        <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                          <h3 className="text font-semibold">Posts</h3>

                          <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                            <span className="font-bold text-gray-700">
                              {formatDate(dateRange?.posts_oldest_date)} -{' '}
                              {formatDate(dateRange?.posts_latest_date)}
                            </span>
                            <br />

                            {/* Total Posts */}
                            {dateRange?.num_posts && (
                              <span className="text-gray-500">
                                Total:{' '}
                                <span className="text-blue-500">
                                  {dateRange?.num_posts}
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    {dateRange?.comments_oldest_date &&
                      dateRange?.comments_latest_date && (
                        <div className="flex flex-col items-start bg-white rounded-lg shadow-md">
                          <h3 className="text font-semibold">Comments</h3>

                          <div className="w-full text-center p-4 border border-gray-200 rounded-lg bg-gray-50">
                            <span className="font-bold text-gray-700">
                              {formatDate(dateRange?.comments_oldest_date)} -{' '}
                              {formatDate(dateRange?.comments_latest_date)}
                            </span>
                            <br />

                            {/* Total Comments */}
                            {dateRange?.num_comments && (
                              <span className="text-gray-500">
                                Total:{' '}
                                <span className="text-blue-500">
                                  {dateRange?.num_comments}
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                  </>
                )}
            </div>
          )}
      {socialMediaLoader && availableDataToggle && (
        <Loader message="Our AI is generating insights from millions of social media data" />
      )}
    </div>
  );
};

export default NumOfPostCommentsNew;
