import React, { useState } from 'react';

const DropdownCheckbox = ({
  options,
  onChange,
  placeholder = 'Select options',
  selectedOptions,
  setSelectedOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOptions, setSelectedOptions] = useState([]);

  console.log(selectedOptions, 'selectedOptions');
  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleCheckboxChange = (option) => {
    const updatedSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];

    setSelectedOptions(updatedSelectedOptions);
    onChange && onChange(updatedSelectedOptions);
  };

  return (
    <div className="relative w-64 font-sans">
      <div
        className="flex justify-between items-center bg-white p-2 border border-gray-300 rounded cursor-pointer hover:bg-gray-200"
        onClick={handleToggle}
      >
        <span className="text-sm text-gray-700">Select Source Data</span>
        <span className="text-xs text-gray-500">{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div className="absolute top-full left-0 right-0 bg-white border border-gray-300 rounded shadow-md mt-1 max-h-60 overflow-y-auto z-10">
          {options.map((option) => (
            <label
              key={option}
              className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              <input
                type="checkbox"
                className="mr-2"
                checked={selectedOptions.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              <span className="text-sm text-gray-700">{option}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownCheckbox;
