import {
  initialLiveSearchHandlers,
  liveSearchStatsDataHandlers,
} from '../services/InsightsDashboard';

import { EngagementEntriesHandlers } from '../services/InsightsDashboard/liveSearch/liveSearchStatsData';

export const handleLiveSearchData = ({
  socialMediaType,
  handle,
  days,
  lang,
  ig_type,
  tiktok_type,
  endDate,
  startDate,
}) => {
  return initialLiveSearchHandlers(socialMediaType, {
    handle,
    days,
    lang,
    ig_type,
    tiktok_type,
    endDate,
    startDate,
  });
};

export const handleStatsData = ({
  socialMediaType,
  handle,
  days,
  lang,
  type,
  interval,
  option,
  country,
  start_date,
  end_date,
  abortSignal,
  ig_type,
  tiktok_type,
  wordsToMatch,
  wordsNotToMatch,
  facebook_keyword,
  post_id,
  gender_type,
  content_type,
  audience_category,
  city,
  data_type,
  tiktok_search_for_user_post,
  tiktok_search_for_keyword_post,
  tiktok_search_for_hashtag_post,
  instagram_search_for_user_post,
  instagram_search_for_tag_post,
  instagram_search_for_hashtag_post,
}) => {
  console.log('handleStatsData', tiktok_type);
  const data = liveSearchStatsDataHandlers(
    socialMediaType,
    {
      handle,
      facebook_keyword,
      days,
      lang,
      interval,
      type,
      option,
      country,
      startDate: start_date,
      endDate: end_date,
      post_id,
      gender_type,
      content_type,
      audience_category,
      city,
      ig_type,
      tiktok_type,
      wordsToMatch,
      wordsNotToMatch,
      data_type,
      tiktok_search_for_user_post,
      tiktok_search_for_keyword_post,
      tiktok_search_for_hashtag_post,
      instagram_search_for_user_post,
      instagram_search_for_tag_post,
      instagram_search_for_hashtag_post,
    },
    abortSignal,
  );
  return data;
};

export const handleEngagementEntriesData = ({
  socialMediaType,
  handle,
  days,
  lang,
  type,
  interval,
  option,
  country,
  start_date,
  end_date,
  abortSignal,
  ig_type,
  tiktok_type,
  wordsToMatch,
  wordsNotToMatch,
  post_id,
  gender_type,
  content_type,
  audience_category,
  city,
  data_type,
}) => {
  const data = EngagementEntriesHandlers(
    socialMediaType,
    {
      handle,
      days,
      lang,
      interval,
      type,
      option,
      country,
      startDate: start_date,
      endDate: end_date,
      post_id,
      gender_type,
      content_type,
      audience_category,
      city,
      ig_type,
      tiktok_type,
      wordsToMatch,
      wordsNotToMatch,
      data_type,
    },
    abortSignal,
  );
  return data;
};
