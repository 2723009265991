import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@uirack/components/ui/avatar';
import {
  BellIcon,
  CheckIcon,
  InfoCircledIcon,
  Pencil1Icon,
} from '@radix-ui/react-icons';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@uirack/components/ui/card';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@uirack/components/ui/popover';
import React, { useContext, useEffect, useState } from 'react';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@uirack/components/ui/sheet';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@uirack/components/ui/tooltip';

import { Button } from '@uirack/components/ui/button';
import { Check } from 'lucide-react';
import { Checkbox } from '@uirack/components/ui/checkbox';
import DataList from '../InsightsDashboard/SearchContainer/DataList';
import NumOfPostComments from '../InsightsDashboard/SearchContainer/NumOfPostComments';
import PropTypes from 'prop-types';
import { SOCIAL_MEDIA_TYPE } from '../../common/constants';
import { Switch } from '@uirack/components/ui/switch';
import { cn } from '@uirack/lib/utils';
import useId from './useId';
import useSearchTermCustomHook from '../customHooks/useSearchTerm.customHook';
import { v4 as uuidv4 } from 'uuid';

// import {
//   Command,
//   CommandEmpty,
//   CommandGroup,
//   CommandInput,
//   CommandItem,
//   CommandList,
//   CommandSeparator,
//   CommandShortcut,
// } from '@uirack/components/ui/command';

const notifications = [
  {
    title: 'User Account',
    description: 'nestle',
    helpText: 'this is to set so and so parameter in search',
  },
  {
    title: 'User Tag',
    description: 'nestle',
    helpText: 'this is to set so and so parameter in search',
  },
  {
    title: 'HashTag',
    description: 'nestle',
    helpText: 'this is to set so and so parameter in search',
  },
];

const ChannelChooser = ({
  mediaItem,
  socialMediaSearchValues,
  searchTerm,
  mainSearchTerm,
  companyInfo,
  setSocialMediaSearchValues,
  setMediaSearchInput,

  masterSearchQuery,
  option,
  onMediaSourceToggle,
  handleOptionSelect,
  youtubeOption,
  twitterOption,
  tiktokOption,
  instagramOption,
  facebookOption,
  setInstagramType,
  setTiktokType,
  setYoutubeType,

  className,
  ...props
}) => {
  // ================================Var==========================
  // const ids = useId()
  const { searchTermLoader, searchTermResponse, handleSearch, error } =
    useSearchTermCustomHook();
  const { icon, label, state, id } = mediaItem;
  const ids = useId();
  const [searchKey, setSearchKey] = useState('nestle');
  const [currentSelection, setCurrentSelection] = useState();
  const [isActiveChannel, setIsActiveChannel] = useState(true);
  const [isActiveTab, setIsActiveTab] = useState([]);
  const [isActive, setIsActive] = useState('userAccount');

  const [open, setOpen] = useState(null);
  const [value, setValue] = useState('');
  // =============================================================

  console.log(mediaItem, searchTerm, searchTermResponse, id, 'props');

  const [instagramHandler, setInstagramHandler] = useState({
    userAccount:
      mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
        ? companyInfo?.tiktokType?.userAccount || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
        ? companyInfo?.instagramType?.userAccount || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube
        ? companyInfo?.youtubeType?.channel || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.twitter
        ? companyInfo?.mediaSearchKeys?.twitter || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.facebook
        ? companyInfo?.mediaSearchKeys?.facebook || ''
        : mediaItem.label.toLowerCase() == 'news'
        ? companyInfo?.mediaSearchKeys?.news || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.reddit
        ? companyInfo?.mediaSearchKeys?.reddit || ''
        : '',

    userTag:
      mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
        ? companyInfo?.tiktokType?.userTag || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
        ? companyInfo?.instagramType?.userTag || ''
        : '',
    keyword:
      mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
        ? companyInfo?.tiktokType?.keyword || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
        ? companyInfo?.instagramType?.keyword || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube
        ? companyInfo?.youtubeType?.keyword || ''
        : '',
    hashtag:
      mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
        ? companyInfo?.tiktokType?.hashtag || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
        ? companyInfo?.instagramType?.hashtag || ''
        : '',
  });

  useEffect(() => {
    // if (mediaItem.label.toLowerCase() != "facebook") {
    setInstagramHandler({
      userAccount:
        mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
          ? companyInfo?.tiktokType?.userAccount || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
          ? companyInfo?.instagramType?.userAccount || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube
          ? companyInfo?.youtubeType?.channel || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.twitter
          ? companyInfo?.mediaSearchKeys?.twitter || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.facebook
          ? companyInfo?.mediaSearchKeys?.facebook || ''
          : mediaItem.label.toLowerCase() == 'news'
          ? companyInfo?.mediaSearchKeys?.news || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.reddit
          ? companyInfo?.mediaSearchKeys?.reddit || ''
          : '',

      userTag:
        mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
          ? companyInfo?.tiktokType?.userTag
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
          ? companyInfo?.instagramType?.userTag || ''
          : '',
      keyword:
        mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
          ? companyInfo?.tiktokType?.keyword || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
          ? companyInfo?.instagramType?.keyword || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube
          ? companyInfo?.youtubeType?.keyword || ''
          : '',
      hashtag:
        mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
          ? companyInfo?.tiktokType?.hashtag || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
          ? companyInfo?.instagramType?.hashtag || ''
          : '',
    });
    // }
  }, [searchKey, companyInfo]);

  useEffect(() => {
    if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok) {
      console.log(companyInfo, 'isActiveChannel');
      let isActiveTiktok = '';
      isActiveTiktok =
        companyInfo?.tiktokType?.userAccount ||
        companyInfo?.tiktokType?.userTag ||
        companyInfo?.tiktokType?.keyword ||
        '';

      console.log(isActiveTiktok, 'isActiveTiktok');
      if (isActiveTiktok != '') {
        console.log(isActiveTiktok, 'isActiveTiktokifff');
        // onMediaSourceToggle(mediaItem.id, true);
        setIsActiveChannel(true);
      } else {
        setIsActiveChannel(false);

        onMediaSourceToggle(mediaItem.id, false);
      }
    }
    if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram) {
      let isActiveInstagram = '';
      isActiveInstagram =
        companyInfo?.instagramType?.userAccount ||
        companyInfo?.instagramType?.userTag ||
        companyInfo?.instagramType?.hashtag ||
        '';

      if (isActiveInstagram != '') {
        setIsActiveChannel(true);
        // onMediaSourceToggle(mediaItem.id, true);
      } else {
        setIsActiveChannel(false);
        onMediaSourceToggle(mediaItem.id, false);
      }
    }
    if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube) {
      let isActiveYoutube = '';
      isActiveYoutube =
        companyInfo?.youtubeType?.channel ||
        companyInfo?.youtubeType?.keyword ||
        '';
      if (isActiveYoutube != '') {
        setIsActiveChannel(true);
        // onMediaSourceToggle(mediaItem.id, true);
      } else {
        setIsActiveChannel(false);
        onMediaSourceToggle(mediaItem.id, false);
      }
    }
    if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.twitter) {
      let isActive = '';
      isActive =
        companyInfo?.mediaSearchKeys?.[SOCIAL_MEDIA_TYPE.twitter] ||
        mainSearchTerm ||
        '';
      if (isActive != '') {
        setIsActiveChannel(true);
        // onMediaSourceToggle(mediaItem.id, true);
      } else {
        setIsActiveChannel(false);
        onMediaSourceToggle(mediaItem.id, false);
      }
    }
    if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.facebook) {
      let isActive = '';
      isActive =
        companyInfo?.mediaSearchKeys?.[SOCIAL_MEDIA_TYPE.facebook] || '';
      if (isActive != '') {
        setIsActiveChannel(true);
        // onMediaSourceToggle(mediaItem.id, true);
      } else {
        setIsActiveChannel(false);
        onMediaSourceToggle(mediaItem.id, false);
      }
    }
    if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.reddit) {
      let isActive = '';
      isActive = companyInfo?.mediaSearchKeys?.[SOCIAL_MEDIA_TYPE.reddit] || '';
      if (isActive != '') {
        setIsActiveChannel(true);
        // onMediaSourceToggle(mediaItem.id, true);
      } else {
        setIsActiveChannel(false);
        onMediaSourceToggle(mediaItem.id, false);
      }
    }
    if (mediaItem.label.toLowerCase() == 'news') {
      let isActive = '';
      isActive = companyInfo?.mediaSearchKeys?.news || '';
      if (isActive != '') {
        setIsActiveChannel(true);
        // onMediaSourceToggle(mediaItem.id, true);
      } else {
        setIsActiveChannel(false);
        onMediaSourceToggle(mediaItem.id, false);
      }
    }

    setIsActiveChannel(true);
  }, [companyInfo, searchKey]);

  useEffect(() => {
    if (mediaItem.label.toLowerCase() == 'tiktok') {
      setTiktokType(instagramHandler);
    }
    if (mediaItem.label.toLowerCase() == 'instagram') {
      setInstagramType(instagramHandler);
    }
  }, [instagramHandler]);

  useEffect(() => {
    updateInstagramOption(currentSelection, isActive);
  }, [currentSelection]);

  console.log(instagramHandler, 'showResponse');

  useEffect(() => {
    if (mediaItem.label.toLowerCase() === SOCIAL_MEDIA_TYPE.youtube) {
      setYoutubeType({
        channel: instagramHandler.userAccount,
        keyword: instagramHandler.keyword,
      });
    }
  }, [instagramHandler, mediaItem.label]);

  useEffect(() => {
    if (mediaItem.label.toLowerCase() != 'facebook') {
      // Check if searchTermResponse already contains an entry with the same key
      const existingIndex = searchTermResponse.findIndex(
        (item) => item.key === '24287259392111',
      );

      // Create a new entry
      const newEntry = {
        key: '24287259392111',
        label: `${
          searchKey
            ? searchKey + '(selected keyword)'
            : mainSearchTerm + '(selected keyword)'
        } `,
        value: searchKey,
        verified: false,
      };

      // If an entry with the same key exists, update it with the new value
      if (existingIndex !== -1) {
        searchTermResponse.splice(existingIndex, 1, newEntry);
      } else {
        // If not, add the new entry at the beginning of the array
        searchTermResponse.unshift({ ...newEntry });
      }
    }
  }, [searchKey, searchTermResponse, companyInfo]);

  function updateInstagramOption(currentSelection, tabName) {
    setInstagramHandler((prevHandler) => {
      // Check if the key already exists and currentSelection is not empty
      if (currentSelection != '' && currentSelection != undefined) {
        // Key exists, update with the latest non-empty value
        setIsActiveTab([...isActiveTab, tabName]);
        return {
          ...prevHandler,
          [tabName]: currentSelection,
        };
      } else {
        // Key doesn't exist, add it with the currentSelection if it's not empty
        return {
          ...prevHandler,
          [tabName]: currentSelection,
        };
      }
      // else {
      //   // Key doesn't exist, and currentSelection is empty, do not update
      //   return prevHandler;
      // }
    });
  }

  const removeHandler = (currentSelection, tabName, optionvalue) => {
    console.log(currentSelection, tabName, optionvalue, 'removeHandler');
    handleNameToggle(tabName);
    // if (mediaItem.label.toLowerCase() != "facebook") {
    handleSearch(
      searchKey,
      mediaItem.label.toLowerCase(),
      optionvalue,
      isActiveChannel,
    );
    // }
    setIsActive(tabName);

    setInstagramHandler((prevHandler) => {
      return {
        ...prevHandler,
        [tabName]: currentSelection,
      };
    });
  };
  const handleNameToggle = (tabName) => {
    console.log(tabName, 'tabName');
    const index = isActiveTab.indexOf(tabName);
    if (index !== -1) {
      // If name is found in the array, remove it
      const updatedNames = [...isActiveTab];
      updatedNames.splice(index, 1);
      setIsActiveTab(updatedNames);
      setCurrentSelection('');
      updateInstagramOption('', tabName);
    } else {
      // If name is not found, add it
      // if (currentSelection != "") {
      setIsActiveTab([...isActiveTab, tabName]);
      // if (currentSelection != "") {
      //   updateInstagramOption(currentSelection, tabName);
      // } else {
      //   updateInstagramOption(searchKey, tabName);
      // }
      // }
      updateInstagramOption(searchKey, tabName);
    }
    // setIsActive(tabName);

    console.log(instagramHandler, 'instagramOption');
  };

  const handleMediaSearch = (searchQuery) => {
    console.log(searchQuery, 'searchQuerysearchQuery');
    setSearchKey(searchQuery);
    setSocialMediaSearchValues((prevData) => ({
      ...prevData,
      [mediaItem.label.toLowerCase()]: searchQuery,
    }));

    console.log(option, mediaItem.label.toLowerCase(), 'sssssoption');
  };

  // main search term change action
  useEffect(() => {
    handleMediaSearch(mainSearchTerm);
    setCurrentSelection(searchTerm);
    setMediaSearchInput(mediaItem.label.toLowerCase(), undefined);
  }, [searchTerm, isActiveChannel]);

  // initial call action
  useEffect(() => {
    handleMediaSearch(
      (socialMediaSearchValues &&
        socialMediaSearchValues[mediaItem.label.toLowerCase()]) ||
        searchTerm,
    );
    setCurrentSelection();
    setMediaSearchInput(mediaItem.label.toLowerCase(), undefined);
  }, []);

  // option toggle action
  useEffect(() => {
    handleMediaSearch(
      (socialMediaSearchValues &&
        socialMediaSearchValues[mediaItem.label.toLowerCase()]) ||
        searchTerm,
    );
    setCurrentSelection();
    setMediaSearchInput(mediaItem.label.toLowerCase(), undefined);
  }, [option]);

  const handleChannelClick = (dataItem) => {
    setOpen(false);
    if (dataItem.value === currentSelection) {
      setCurrentSelection();
      setMediaSearchInput(mediaItem.label.toLowerCase(), undefined);
    } else {
      if (mediaItem.label.toLowerCase() === 'facebook') {
        setCurrentSelection(dataItem.value);
        setMediaSearchInput(
          mediaItem.label.toLowerCase(),
          dataItem.value,
          dataItem.label,
        );
      } else if (mediaItem.label.toLowerCase() === 'linkedin') {
        setCurrentSelection(dataItem.value);
        setMediaSearchInput(
          mediaItem.label.toLowerCase(),
          dataItem.value,
          dataItem.url,
        );
      } else {
        setCurrentSelection(dataItem.value);
        setMediaSearchInput(
          mediaItem.label.toLowerCase(),
          dataItem.value,
          dataItem.value,
        );
      }
    }
  };

  const checkedHandler = (isChecked, fieldName) => {
    // Assuming instagramHandler is part of the component's state
    // Update the specific field based on checkbox state
    setInstagramHandler((prev) => ({
      ...prev,
      [fieldName]: isChecked ? prev.userAccount : '',
    }));
  };

  const frameworks = [
    {
      value: 'next.js',
      label: 'Next.js',
    },
    {
      value: 'sveltekit',
      label: 'SvelteKit',
    },
    {
      value: 'nuxt.js',
      label: 'Nuxt.js',
    },
    {
      value: 'remix',
      label: 'Remix',
    },
    {
      value: 'astro',
      label: 'Astro',
    },
  ];
  const handleOpenChange = (cardId) => {
    setOpen(cardId); // Open the clicked card and close others
  };
  console.log(
    instagramHandler,
    instagramHandler.userAccount,
    'instagramHandler',
  );
  return (
    <Card
      className={cn('w-[280px] h-[380px] flex flex-col bg-white', className)}
      {...props}
      id={ids}
    >
      <CardHeader>
        <CardTitle>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Avatar>
                <AvatarImage
                  src={`/icons/social-media-icons/${
                    state || isActiveChannel ? 'colored' : 'glyph'
                  }/${icon}.svg`}
                />
                <AvatarFallback>{label}</AvatarFallback>
              </Avatar>
              <CardDescription>
                <div className="mx-4">
                  {socialMediaSearchValues[mediaItem?.label.toLowerCase()] ||
                    searchTerm ||
                    mainSearchTerm}
                </div>

                <Sheet>
                  <SheetTrigger asChild>
                    <Button variant="link" className="text-xs -my-1 text-muted">
                      View date limits
                    </Button>
                  </SheetTrigger>
                  <SheetContent>
                    <SheetHeader>
                      <SheetTitle>Available Data</SheetTitle>
                    </SheetHeader>
                    {companyInfo?.[mediaItem.label.toLowerCase()] && (
                      <NumOfPostComments
                        socialMedia={mediaItem.label.toLowerCase()}
                        mainSearchTerm={mainSearchTerm}
                      />
                    )}
                  </SheetContent>
                </Sheet>
              </CardDescription>
            </div>
            <div className="flex-end">
              <div className="text-black visible">
                <Switch />
              </div>
            </div>
          </div>
        </CardTitle>
      </CardHeader>
      <>
        {(mediaItem.state || isActiveChannel) &&
          (mediaItem.label.toLowerCase() === 'youtube' ||
            mediaItem.label.toLowerCase() === 'twitter' ||
            mediaItem.label.toLowerCase() === 'news' ||
            mediaItem.label.toLowerCase() === 'reddit' ||
            mediaItem.label.toLowerCase() === 'facebook' ||
            mediaItem.label.toLowerCase() === 'tiktok' ||
            mediaItem.label.toLowerCase() === 'instagram') && (
            <CardContent className="grid gap-4">
              {/* ================================================= */}
              <div>
                {(mediaItem.label.toLowerCase() === 'youtube' ||
                  // mediaItem.label.toLowerCase() === "twitter" ||
                  mediaItem.label.toLowerCase() === 'tiktok' ||
                  mediaItem.label.toLowerCase() === 'instagram') && (
                  <div
                    // key={index}
                    className="mb-2 grid grid-cols-[25px_1fr_25px] items-start justify-between pb-2 last:mb-0 last:pb-0"
                  >
                    <Checkbox
                      checked={instagramHandler.userAccount !== ''}
                      onChange={(e) =>
                        checkedHandler(e.target.checked, 'userAccount')
                      }
                    />
                    <label
                      htmlFor={
                        (mediaItem.label.toLowerCase() === 'youtube' &&
                          'youtubemode') ||
                        (mediaItem.label.toLowerCase() === 'twitter' &&
                          'twittermode') ||
                        (mediaItem.label.toLowerCase() === 'tiktok' &&
                          'tiktokmode') ||
                        (mediaItem.label.toLowerCase() === 'instagram' &&
                          'instagrammode') ||
                        (mediaItem.label.toLowerCase() === 'facebook' &&
                          'facebookmode')
                      }
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      <div className="space-y-1">
                        <p className="flex text-sm font-medium leading-none">
                          {(mediaItem.label.toLowerCase() === 'youtube' &&
                            'Channel') ||
                            (mediaItem.label.toLowerCase() === 'twitter' &&
                              'User Handler') ||
                            (mediaItem.label.toLowerCase() === 'tiktok' &&
                              'User Account') ||
                            (mediaItem.label.toLowerCase() === 'instagram' &&
                              'User Account')}
                        </p>

                        {instagramHandler?.userAccount && (
                          <p className="flex items-center text-sm text-muted-foreground">
                            {instagramHandler?.userAccount?.length > 10
                              ? `${instagramHandler.userAccount.slice(0, 10)}..`
                              : instagramHandler.userAccount}

                            <Popover
                              key={'userAccount'}
                              open={open === 'userAccount'}
                              onOpenChange={() =>
                                handleOpenChange('userAccount')
                              }
                            >
                              <PopoverTrigger asChild>
                                <Button
                                  variant="ghost"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSearch(
                                      searchKey,
                                      mediaItem.label.toLowerCase(),
                                      undefined,
                                      isActiveChannel,
                                    );
                                  }}
                                  aria-label="Edit"
                                >
                                  <Pencil1Icon />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent className="w-[280px] h-[400px] p-2  bg-white  overflow-auto">
                                {searchTermResponse.map((resultItem) => (
                                  <DataList
                                    key={uuidv4()}
                                    dataItem={resultItem}
                                    handleChannelClick={handleChannelClick}
                                    currentSelection={currentSelection}
                                  />
                                ))}
                                {/* <Command>
                                  <CommandInput placeholder="Search framework..." />
                                  <CommandEmpty>
                                    No framework found.
                                  </CommandEmpty>
                                  <CommandGroup>
                                    {frameworks.map((framework) => (
                                      <CommandItem
                                        key={framework.value}
                                        value={framework.value}
                                        onSelect={(currentValue) => {
                                          setValue(
                                            currentValue === value
                                              ? ''
                                              : currentValue,
                                          );
                                          setOpen(false);
                                        }}
                                      >
                                        <Check
                                          className={cn(
                                            'mr-2 h-4 w-4',
                                            value === framework.value
                                              ? 'opacity-100'
                                              : 'opacity-0',
                                          )}
                                        />
                                        {framework.label}
                                      </CommandItem>
                                    ))}
                                  </CommandGroup>
                                </Command> */}
                              </PopoverContent>
                            </Popover>
                            {/* <Sheet>
                              <SheetTrigger asChild>
                                <Button
                                  variant="ghost"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSearch(
                                      searchKey,
                                      mediaItem.label.toLowerCase(),
                                      undefined,
                                      isActiveChannel,
                                    );
                                  }}
                                  aria-label="Edit"
                                >
                                  <Pencil1Icon />
                                </Button>
                              </SheetTrigger>
                              <SheetContent>
                                <SheetHeader>
                                  <SheetTitle>User Account</SheetTitle>
                                </SheetHeader>
                                <div className="grid gap-4 py-4">
                                  <div className="grid grid-cols-4 items-center gap-4">
                                    <input
                                      id="name"
                                      value={instagramHandler.userAccount || ''}
                                      className="col-span-3"
                                    />
                                  </div>
                                </div>
                                {searchTermResponse.map((resultItem) => (
                                  <DataList
                                    key={uuidv4()}
                                    dataItem={resultItem}
                                    handleChannelClick={handleChannelClick}
                                    currentSelection={currentSelection}
                                  />
                                ))}
                                <>
                                    <Command className="rounded-lg border shadow-md" id={ids}>
                                      <CommandInput id={ids} placeholder="Type a command or search..." />
                                      <CommandList>
                                        <CommandEmpty>
                                          No results found.
                                        </CommandEmpty>
                                        <CommandGroup heading="Suggestions">
                                          <CommandItem>
                                            <span>Calendar</span>
                                          </CommandItem>
                                          <CommandItem>
                                            <span>Search Emoji</span>
                                          </CommandItem>
                                          <CommandItem>
                                            <span>Calculator</span>
                                          </CommandItem>
                                        </CommandGroup>
                                        <CommandSeparator />
                                      </CommandList>
                                    </Command>
                                  </>
                                <SheetFooter>
                                  <SheetClose asChild>
                                    <Button type="submit">Save changes</Button>
                                  </SheetClose>
                                </SheetFooter>
                              </SheetContent>
                            </Sheet> */}
                          </p>
                        )}
                      </div>
                    </label>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button variant="ghost" size="icon">
                            <InfoCircledIcon className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent className="bg-gray-200 text-black">
                          <p>{instagramHandler?.userAccount}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                )}
              </div>
              {/* ================================================== */}
              <div>
                {mediaItem.label.toLowerCase() === 'instagram' ? (
                  <div className="mb-2 grid grid-cols-[25px_1fr_25px] items-start justify-between pb-2 last:mb-0 last:pb-0">
                    <Checkbox id="terms" />

                    <div className="space-y-1">
                      <p className="flex text-sm font-medium leading-none">
                        User Tag{' '}
                      </p>

                      {instagramHandler?.userTag && (
                        <p className="flex items-center text-sm text-muted-foreground">
                          {instagramHandler?.userTag?.length > 10
                            ? `${instagramHandler.userTag.slice(0, 10)}..`
                            : instagramHandler.userTag}
                          {/* <Popover open={open} onOpenChange={setOpen}>
                            <PopoverTrigger asChild>
                              <Button
                                variant="ghost"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSearch(
                                    searchKey,
                                    mediaItem.label.toLowerCase(),
                                    undefined,
                                    isActiveChannel,
                                  );
                                }}
                                aria-label="Edit"
                              >
                                <Pencil1Icon />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[280px] h-[400px] p-2  bg-white  overflow-auto">
                              {searchTermResponse.map((resultItem) => (
                                <DataList
                                  key={uuidv4()}
                                  dataItem={resultItem}
                                  handleChannelClick={handleChannelClick}
                                  currentSelection={currentSelection}
                                />
                              ))}
                            </PopoverContent>
                          </Popover> */}
                          <Sheet>
                            <SheetTrigger asChild>
                              <Button
                                variant="ghost"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSearch(
                                    searchKey,
                                    mediaItem.label.toLowerCase(),
                                    undefined,
                                    isActiveChannel,
                                  );
                                }}
                                aria-label="Edit"
                              >
                                <Pencil1Icon />
                              </Button>
                            </SheetTrigger>
                            <SheetContent>
                              <SheetHeader>
                                <SheetTitle>User Tag</SheetTitle>
                              </SheetHeader>
                              <div className="grid gap-4 py-4">
                                <div className="grid grid-cols-4 items-center gap-4">
                                  <input
                                    id="name"
                                    value={instagramHandler.userTag || ''}
                                    className="col-span-3"
                                  />
                                </div>
                              </div>
                              {searchTermResponse.map((resultItem) => (
                                <DataList
                                  key={uuidv4()}
                                  dataItem={resultItem}
                                  handleChannelClick={handleChannelClick}
                                  currentSelection={currentSelection}
                                />
                              ))}
                              {/* <>
                                <Command
                                  className="rounded-lg border shadow-md"
                                  id={ids}
                                >
                                  <CommandInput
                                    id={ids}
                                    placeholder="Type a command or search..."
                                  />
                                  <CommandList>
                                    <CommandEmpty>
                                      No results found.
                                    </CommandEmpty>
                                    <CommandGroup heading="Suggestions">
                                      <CommandItem>
                                        <span>Calendar</span>
                                      </CommandItem>
                                      <CommandItem>
                                        <span>Search Emoji</span>
                                      </CommandItem>
                                      <CommandItem>
                                        <span>Calculator</span>
                                      </CommandItem>
                                    </CommandGroup>
                                    <CommandSeparator />
                                  </CommandList>
                                </Command>
                              </> */}
                              <SheetFooter>
                                <SheetClose asChild>
                                  <Button type="submit">Save changes</Button>
                                </SheetClose>
                              </SheetFooter>
                            </SheetContent>
                          </Sheet>
                        </p>
                      )}
                    </div>

                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button variant="ghost" size="icon">
                            <InfoCircledIcon className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent className="bg-gray-200 text-black">
                          <p>{instagramHandler?.userTag}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                ) : (
                  (mediaItem.label.toLowerCase() === 'tiktok' ||
                    mediaItem.label.toLowerCase() === 'youtube') && (
                    <div className="mb-2 grid grid-cols-[25px_1fr_25px] items-start justify-between pb-2 last:mb-0 last:pb-0">
                      <Checkbox id="terms" />

                      <div className="space-y-1">
                        <p className="flex text-sm font-medium leading-none">
                          Keyword
                        </p>

                        {instagramHandler?.keyword && (
                          <p className="flex items-center text-sm text-muted-foreground">
                            {instagramHandler?.keyword?.length > 10
                              ? `${instagramHandler.keyword.slice(0, 10)}..`
                              : instagramHandler.keyword}
                            <Popover
                              key={'keyword'}
                              open={open === 'keyword'}
                              onOpenChange={() => handleOpenChange('keyword')}
                            >
                              <PopoverTrigger asChild>
                                <Button
                                  variant="ghost"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSearch(
                                      searchKey,
                                      mediaItem.label.toLowerCase(),
                                      undefined,
                                      isActiveChannel,
                                    );
                                  }}
                                  aria-label="Edit"
                                >
                                  <Pencil1Icon />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent className="w-[280px] h-[400px] p-2  bg-white  overflow-auto">
                                {searchTermResponse.map((resultItem) => (
                                  <DataList
                                    key={uuidv4()}
                                    dataItem={resultItem}
                                    handleChannelClick={handleChannelClick}
                                    currentSelection={currentSelection}
                                  />
                                ))}
                              </PopoverContent>
                            </Popover>
                          </p>
                        )}
                      </div>

                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button variant="ghost" size="icon">
                              <InfoCircledIcon className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent className="bg-gray-200 text-black">
                            <p>{instagramHandler.keyword}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  )
                )}
              </div>
              {/* ===================================================== */}

              <div>
                {mediaItem.label.toLowerCase() === 'instagram' ||
                mediaItem.label.toLowerCase() === 'tiktok' ? (
                  <div
                    // key={index}
                    className="mb-2 grid grid-cols-[25px_1fr_25px] items-start justify-between pb-2 last:mb-0 last:pb-0"
                  >
                    <Checkbox id="terms" />

                    <div className="space-y-1">
                      <p className="flex text-sm font-medium leading-none">
                        Hashtag
                      </p>

                      {instagramHandler?.hashtag && (
                        <p className="flex items-center text-sm text-muted-foreground">
                          {instagramHandler?.hashtag?.length > 10
                            ? `${instagramHandler.hashtag.slice(0, 10)}..`
                            : instagramHandler.hashtag}
                          <Popover
                            key={'hashtag'}
                            open={open === 'hashtag'}
                            onOpenChange={() => handleOpenChange('hashtag')}
                          >
                            <PopoverTrigger asChild>
                              <Button
                                variant="ghost"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSearch(
                                    searchKey,
                                    mediaItem.label.toLowerCase(),
                                    undefined,
                                    isActiveChannel,
                                  );
                                }}
                                aria-label="Edit"
                              >
                                <Pencil1Icon />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[280px] h-[400px] p-2  bg-white  overflow-auto">
                              {searchTermResponse.map((resultItem) => (
                                <DataList
                                  key={uuidv4()}
                                  dataItem={resultItem}
                                  handleChannelClick={handleChannelClick}
                                  currentSelection={currentSelection}
                                />
                              ))}
                            </PopoverContent>
                          </Popover>
                        </p>
                      )}
                    </div>

                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button variant="ghost" size="icon">
                            <InfoCircledIcon className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent className="bg-gray-200 text-black">
                          <p>{instagramHandler?.hashtag}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                ) : null}
              </div>
            </CardContent>
          )}
      </>
      <CardFooter className="cardFooter mt-auto w-full">
        <Button className="w-full bg-gray-200 text-black hover:bg-gray-800 hover:text-white">
          <CheckIcon className="mr-2 h-4 w-4" /> Save Changes
        </Button>
      </CardFooter>
    </Card>
  );
};

ChannelChooser.propTypes = {
  mediaItem: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    state: PropTypes.bool,
  }),
  option: PropTypes.bool,
  masterSearchQuery: PropTypes.string,
  onMediaSourceToggle: PropTypes.func,
  setMediaSearchInput: PropTypes.func,
  handleOptionSelect: PropTypes.func,
  socialMediaSearchValues: PropTypes.shape({
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    reddit: PropTypes.string,
    news: PropTypes.string,
    tiktok: PropTypes.string,
    linkedin: PropTypes.string,
    youtube: PropTypes.string,
  }),
  setSocialMediaSearchValues: PropTypes.func.isRequired,
};

export default React.memo(ChannelChooser);
