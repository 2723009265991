import {
  INSTAGRAM_TYPE,
  SOCIAL_MEDIA_TYPE,
  TIKTOK_TYPE,
} from '../../../common/constants';
import React, { useContext, useEffect, useState } from 'react';

import ChannelCard from './ChannelCard';
import ChannelCardPortfolio from './ChannelCardPortfolio';
import ChannelChooser from '../../ComponentDesigns/ChannelChooser';
import NewChannelChoosWithoutKeyword from '../../ComponentDesigns/NewChannelChoosWithoutKeyword';
import NewChannelChooser from '../../ComponentDesigns/NewChannelChooser';
import NumOfPostComments from './NumOfPostComments';
import PropTypes from 'prop-types';
import { UserProfileContext } from '../../../store/context/profile.context';
import useStatsSearchCustomHook from '../../customHooks/useStatsSearch.customHook';

const MediaWellPortfolio = ({
  socialMediaChannels,
  youtubeOption,
  twitterOption,
  tiktokOption,
  instagramOption,
  facebookOption,
  youtubeType,
  setTiktokType,
  setInstagramType,
  setYoutubeType,
  setSelectedCompanyid,
  companyInfo,
  masterSearchQuery,
  showNew,
  ...props
}) => {
  const mainSearchTerm = companyInfo?.mainSearchTerm;

  console.log(companyInfo, socialMediaChannels, 'companyInfocompanyInfo');

  return (
    <div>
      <div className="overflow-x-scroll overflow-y-hidden  flex px-1 gap-4">
        {socialMediaChannels.map((mediaItem, index) => {
          let searchTerm = '';
          searchTerm =
            mediaItem.label.toLowerCase() != SOCIAL_MEDIA_TYPE.facebook
              ? companyInfo?.[mediaItem.label.toLowerCase()] ||
                companyInfo?.[mediaItem.label.toLowerCase()]
              : companyInfo?.[mediaItem.label.toLowerCase()] || mainSearchTerm;
          return (
            <div className="flex flex-col my-2" key={mediaItem.id}>
              {mediaItem.label.toLowerCase() === 'tiktok' ||
              mediaItem.label.toLowerCase() === 'instagram' ||
              mediaItem.label.toLowerCase() === 'youtube' ? (
                <NewChannelChooser
                  showNew={showNew}
                  masterSearchQuery={masterSearchQuery}
                  key={mediaItem.id}
                  id={index}
                  mediaItem={mediaItem}
                  option={
                    (mediaItem.label.toLowerCase() === 'tiktok' &&
                      tiktokOption) ||
                    (mediaItem.label.toLowerCase() === 'facebook' &&
                      facebookOption) ||
                    (mediaItem.label.toLowerCase() === 'instagram' &&
                      instagramOption) ||
                    undefined
                  }
                  {...props}
                  youtubeOption={youtubeOption}
                  twitterOption={twitterOption}
                  tiktokOption={tiktokOption}
                  instagramOption={instagramOption}
                  facebookOption={facebookOption}
                  setTiktokType={setTiktokType}
                  setYoutubeType={setYoutubeType}
                  setInstagramType={setInstagramType}
                  searchTerm={searchTerm}
                  companyInfo={companyInfo}
                  mainSearchTerm={mainSearchTerm}
                />
              ) : mediaItem.label.toLowerCase() === 'twitter' ||
                mediaItem.label.toLowerCase() === 'facebook' ||
                mediaItem.label.toLowerCase() === 'news' ||
                mediaItem.label.toLowerCase() === 'reddit' ||
                (mediaItem.label.toLowerCase() === 'linkedin' && showNew) ? (
                <NewChannelChoosWithoutKeyword
                  showNew={showNew}
                  masterSearchQuery={masterSearchQuery}
                  id={index}
                  mediaItem={mediaItem}
                  option={
                    (mediaItem.label.toLowerCase() === 'tiktok' &&
                      tiktokOption) ||
                    (mediaItem.label.toLowerCase() === 'facebook' &&
                      facebookOption) ||
                    (mediaItem.label.toLowerCase() === 'instagram' &&
                      instagramOption) ||
                    undefined
                  }
                  {...props}
                  youtubeOption={youtubeOption}
                  twitterOption={twitterOption}
                  tiktokOption={tiktokOption}
                  instagramOption={instagramOption}
                  facebookOption={facebookOption}
                  setTiktokType={setTiktokType}
                  setYoutubeType={setYoutubeType}
                  setInstagramType={setInstagramType}
                  searchTerm={searchTerm}
                  companyInfo={companyInfo}
                  mainSearchTerm={mainSearchTerm}
                />
              ) : null}

              {/* <ChannelChooser
                // key={mediaItem.id}
                id={index}
                mediaItem={mediaItem}
                option={
                  (mediaItem.label.toLowerCase() === 'tiktok' &&
                    tiktokOption) ||
                  (mediaItem.label.toLowerCase() === 'facebook' &&
                    facebookOption) ||
                  (mediaItem.label.toLowerCase() === 'instagram' &&
                    instagramOption) ||
                  undefined
                }
                {...props}
                youtubeOption={youtubeOption}
                twitterOption={twitterOption}
                tiktokOption={tiktokOption}
                instagramOption={instagramOption}
                facebookOption={facebookOption}
                setTiktokType={setTiktokType}
                setYoutubeType={setYoutubeType}
                setInstagramType={setInstagramType}
                searchTerm={searchTerm}
                companyInfo={companyInfo}
                mainSearchTerm={mainSearchTerm}
              /> */}
              {/* <ChannelCardPortfolio
                key={mediaItem.id}
                mediaItem={mediaItem}
                option={
                  (mediaItem.label.toLowerCase() === 'tiktok' &&
                    tiktokOption) ||
                  (mediaItem.label.toLowerCase() === 'facebook' &&
                    facebookOption) ||
                  (mediaItem.label.toLowerCase() === 'instagram' &&
                    instagramOption) ||
                  undefined
                }
                {...props}
                youtubeOption={youtubeOption}
                twitterOption={twitterOption}
                tiktokOption={tiktokOption}
                instagramOption={instagramOption}
                facebookOption={facebookOption}
                setTiktokType={setTiktokType}
                setYoutubeType={setYoutubeType}
                setInstagramType={setInstagramType}
                searchTerm={searchTerm}
                companyInfo={companyInfo}
                mainSearchTerm={mainSearchTerm}
              /> */}
              {/* {companyInfo?.mediaSearchKeys?.[
                mediaItem.label.toLowerCase()
              ] && (
                <NumOfPostComments
                  socialMedia={mediaItem.label.toLowerCase()}
                  mainSearchTerm={mainSearchTerm}
                />
              )} */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

MediaWellPortfolio.propTypes = {
  socialMediaChannels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
};

export default React.memo(MediaWellPortfolio);
