import React, { useContext } from 'react';

import ManageCompaniesViewGroup from '../modules/UserProfile/ManageCompanies/ManageCompaniesViewGroup';
import ManageCopilotSubscription from '../modules/UserProfile/ManageFeatures/ManageCopilotSubscription';
import SearchContainer from '../modules/ActiveFollowers/index';
import { UserProfileContext } from '../store/context/profile.context';

// import Subscription from './../modules/UserProfile/ManageFeatures/Subscription/Subscription';

// import ManageLeadIQSubscription from '../modules/UserProfile/ManageFeatures/ManageLeadIQSubscription';
// import ManageLinkedinSubscription from '../modules/UserProfile/ManageFeatures/ManageLinkedinSubscription';

const AiFullPage = React.lazy(() => import('../chatgpt_summarize/AiFullPage'));

const ManageCompaniesViewNew = React.lazy(() =>
  import('../modules/UserProfile/ManageCompanies/ManageCompaniesViewNew'),
);

const ManageLinkedinSubscription = React.lazy(() =>
  import('../modules/UserProfile/ManageFeatures/ManageLinkedinSubscription'),
);
const ManageLeadIQSubscription = React.lazy(() =>
  import('../modules/UserProfile/ManageFeatures/ManageLeadIQSubscription'),
);

const InsightsDashboard = React.lazy(() =>
  import('../modules/InsightsDashboard'),
);
const ResultsContainer = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);
const PortfolioContainer = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/PortfolioContainer'),
);

// active influencers routes
const activeInfluencers = React.lazy(() =>
  import('../modules/ActiveFollowers'),
);

const UserProfile = React.lazy(() => import('../modules/UserProfile'));
const Logout = React.lazy(() => import('../modules/Logout'));
const UndefinedRoute = React.lazy(() =>
  import('../modules/App/UndefinedRoute'),
);

const FacebookAdsResultContainer = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);

const FacebookCampaignAdTrends = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);

const SocialMediainsight = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);
// const FacebookCampaignAdTrends = React.lazy(() =>
//   import("../modules/FacebookCampaignTrends/facebookCampaignTrends")
// );

const AppPreferencesView = React.lazy(() =>
  import('../modules/UserProfile/AppPreferences/AppPreferencesView'),
);
const ManageFeaturesView = React.lazy(() =>
  import('../modules/UserProfile/ManageFeatures/ManageFeaturesView'),
);
const ManageSubscription = React.lazy(() =>
  import('../modules/UserProfile/ManageFeatures/ManageSubscription'),
);

// const Subscription = React.lazy(() =>
// import("../modules/UserProfile/Subscription/Subscription")
// );
const ManageCompaniesView = React.lazy(() =>
  import('../modules/UserProfile/ManageCompanies/ManageCompaniesView'),
);

const ManageSalesNavigatorsView = React.lazy(() =>
  import(
    '../modules/UserProfile/ManageSalesNavigator/ManageSalesNavigatorsView'
  ),
);
const ManageJobSearchesView = React.lazy(() =>
  import('../modules/UserProfile/ManageJobSearches/ManageJobSearchesView'),
);
const ManageCompanyDetailsView = React.lazy(() =>
  import(
    '../modules/UserProfile/ManageCompanyDetailsView/ManageCompanyDetailsView'
  ),
);
const ConnectedAccounts = React.lazy(() =>
  import('../modules/UserProfile/ManageSocialmedia/ConnectedAccounts'),
);

const LinkedinCompanyInsights = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);

const LinkedinPublicPageInsights = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);

const ChatGPT_Playground = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);

// const ChatGPT_Chatboot = React.lazy(() =>
//   import("../modules/RealTimeChatGptInsights/ChatGPTBoot/ChatGPTBoot")
// );

// const CHATGPT_Prompts = React.lazy(() =>
//   import("../modules/ChatGPTPrompts/ChatGPT_LandingPage/LandingPage")
// );
const Lead = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);
const ManageLink = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);
const showLead = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);
const JobInsights = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);
const Linkdinapolloinsights = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);
const TrustPilot = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);
const LinkdinapolloinsightsPeople = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);

const CompanyDetailPage = React.lazy(() =>
  import('../modules/InsightsDashboard/ResultsView/ResultsContainer'),
);

// const profileContext = useContext(UserProfileContext);

// const { profileInfo = {} } = profileContext;

export const routes = [
  { path: '/profile', name: 'Profile', component: UserProfile },
  // {
  //   path: '/socialinsights',
  //   name: 'ActionableInsights',
  //   component: InsightsDashboard,
  // },
  {
    path: '/socialinsights',
    name: 'ActionableInsights',
    component: SearchContainer,
  },
  {
    path: '/influencers/activeFollowers',
    name: 'ActiveFollowers',
    component: activeInfluencers,
  },
  {
    path: '/influencers/activeInfluencers',
    name: 'ActiveFollowers',
    component: activeInfluencers,
  },
  {
    path: '/socialMentionsSmartInbox/mentionsTimeline',
    name: 'ActiveFollowers',
    component: InsightsDashboard,
  },
  {
    path: '/MentionsTimelineResultsView/*',
    name: 'MentionsTimeline',
    component: ResultsContainer,
  },
  {
    path: '/resultsView/*',
    name: 'ResultsContainer',
    component: ResultsContainer,
  },
  {
    path: '/favouritesdashboard',
    name: 'FavouritesDashboard',
    component: ResultsContainer,
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: ResultsContainer,
  },
  {
    path: '/ActiveFollowersResultsView/*',
    name: 'ActiveFollowersResultsContainer',
    component: ResultsContainer,
  },
  {
    path: '/ActiveInfluencersResultsView/*',
    name: 'ActiveInfluencersResultsContainer',
    component: ResultsContainer,
  },
  {
    path: '/searchresult',
    name: 'LandingPage',
    component: InsightsDashboard,
  },
  {
    path: '/portfolio/trackcompanies',
    name: 'PortfolioInsightsTrackCompanies',
    component: UserProfile,
    exact: true,
  },

  {
    path: '/profile/linkedinSubscription',
    name: 'ProfileLinkedinSubscription',
    component: UserProfile,
    exact: true,
  },
  {
    path: '/profile/genaiSubscription',
    name: 'ProfilegenaiSubscription',
    component: UserProfile,
    exact: true,
  },

  {
    path: '/profile/subscription',
    name: 'ProfileSubscription',
    component: UserProfile,
    exact: true,
  },

  {
    path: '/portfolio/tracksalesnavigators',
    name: 'ProfileSubscription',
    component: UserProfile,
    exact: true,
  },

  {
    path: '/portfolio/insights/:id',
    name: 'PortfolioInsights',
    component: PortfolioContainer,
    exact: true,
  },
  {
    path: '/portfolio/insights/*',
    name: 'PortfolioInsights',
    component: PortfolioContainer,
    exact: true,
  },
  {
    path: '/portfolio/dashboard/*',
    name: 'PortfolioInsightsCompititor',
    component: PortfolioContainer,
    exact: true,
  },

  {
    path: '/portfolio/comparison-dashboard/*',
    name: 'PortfolioInsightsCompititornew',
    component: PortfolioContainer,
    exact: true,
  },
  {
    path: '/linkedin/insights',
    name: 'Linkedin Insights',
    component: LinkedinCompanyInsights,
    exact: true,
  },
  {
    path: '/genai-insights',
    name: 'Utility Functions',
    component: ManageLink,
    exact: true,
  },
  {
    path: '/upload-pdf',
    name: 'PDF Copilot Assistant',
    component: ManageLink,
    exact: true,
  },

  {
    path: '/testimonial',
    name: 'Testimonial',
    component: ManageLink,
    exact: true,
  },

  {
    path: '/genai-live',
    name: 'Gen AI social media search',
    component: ManageLink,
    exact: true,
  },

  // {
  //   path: '/genai-live',
  //   name: 'Gen AI',
  //   component: ManageLink,
  //   exact: true,
  // },

  {
    /**
     * do not remove this two route which looks similar to you.
     * `/linkedin/public_page` and `/linkedin/public_page/:id` this 2 may look similar to you.
     * but if you remove anyone of them then the Apollo table page will break and will display white page to you.
     *
     * When you click on 'Sales lead insight' you will be re-directed to `/linkedin/public_page`.
     * After the page will be loaded the url will be change and you will be re-directed to `/linkedin/public_page/:id`.
     * Eveytime you click on `tabs` the url will change and it re-render(update table with API response) component on this `/linkedin/public_page/:id` url.
     *
     * if you have any good alternative for this then you are welcome to change it.
     * if you still have any questions ask me.
     *
     * -sarfaraj [MrDark]
     */
    path: '/linkedin/public_page/:id',
    name: 'Linkedin Public Page Insights',
    component: LinkedinPublicPageInsights,
    exact: true,
  },
  {
    path: '/linkedin/public_page/',
    name: 'Linkedin Public Page Insights',
    component: LinkedinPublicPageInsights,
    exact: true,
  },
  {
    path: '/ad/facebook',
    name: 'Facebook Ad Insights',
    component: FacebookAdsResultContainer,
    exact: true,
  },
  {
    path: '/lead/generation/:id',
    name: 'Lead Generation',
    component: Lead,
    exact: true,
  },
  {
    path: '/lead/generation/',
    name: 'Lead Generation ',
    component: Lead,
    exact: true,
  },
  {
    path: '/lead/socialmediainsights',
    name: 'SocialMedia',
    component: SocialMediainsight,
    exact: true,
  },
  {
    path: '/lead/jobinsights',
    name: 'Job Insights',
    component: JobInsights,
    exact: true,
  },
  {
    path: '/lead/jobinsights/:id',
    name: 'Job Insights',
    component: JobInsights,
    exact: true,
  },
  {
    path: '/trustpilot',
    name: 'Trust Pilot ',
    component: TrustPilot,
    exact: true,
  },
  {
    path: '/trustpilot/:id',
    name: 'Trust Pilot ',
    component: TrustPilot,
    exact: true,
  },
  {
    /**
     * do not remove this two route which looks similar to you.
     * `/lead/salesleadinsights` and `/lead/salesleadinsights/:id` this 2 may look similar to you.
     * but if you remove anyone of them then the Apollo table page will break and will display white page to you.
     *
     * When you click on 'Sales lead insight' you will be re-directed to `/lead/salesleadinsights`.
     * After the page will be loaded the url will be change and you will be re-directed to `/lead/salesleadinsights/:id`.
     * Eveytime you click on `tabs` the url will change and it re-render(update table with API response) component on this `/lead/salesleadinsights/:id` url.
     *
     * if you have any good alternative for this then you are welcome to change it.
     * if you still have any questions ask me.
     *
     * -sarfaraj [MrDark]
     */
    path: '/lead/salesleadinsights',
    name: 'Lead Sales',
    component: Linkdinapolloinsights,
    exact: true,
  },
  {
    path: '/lead/salesleadinsights/:id',
    name: 'Lead Sales ',
    component: Linkdinapolloinsights,
    exact: true,
    /**
     * This is end.
     */
  },
  {
    path: '/lead/linkdin/people/:id',
    name: 'Lead People',
    component: LinkdinapolloinsightsPeople,
    exact: true,
  },

  {
    path: '/lead/linkdin/company/:id',
    name: 'Lead People',
    component: LinkdinapolloinsightsPeople,
    exact: true,
  },
  {
    path: '/lead/linkdin/company/',
    name: 'Lead People',
    component: LinkdinapolloinsightsPeople,
    exact: true,
  },
  {
    /**
     * This will be removes later after the link implimentation will be completed.
     * for now it will prevent people detail from crashing.
     */
    path: '/lead/linkdin/people/',
    name: 'Lead People',
    component: LinkdinapolloinsightsPeople,
    exact: true,
  },
  // {
  //   path: "/lead/managelink",
  //   name: "Manage Leads Links ",
  //   component: ManageLink,
  //   exact: true,
  // },
  {
    path: '/excell/uploadlist',
    name: 'Upload Leads ',
    component: ManageLink,
    exact: true,
  },
  {
    path: '/lead/showLead',
    name: 'Show Lead ',
    component: showLead,
    exact: true,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/',
    name: 'profile_user',
    component: UserProfile,
  },
  {
    path: '*',
    name: 'UndefinedRoute',
    component: UndefinedRoute,
  },
  {
    path: '/ad/trends',
    name: 'Facebook Campaign Ad Trends',
    component: FacebookCampaignAdTrends,
  },
  {
    path: '/gpt/chatGpt',
    name: 'ChatGPT Playground',
    component: ChatGPT_Playground,
    exact: true,
  },
  // {
  //   path: "/gpt/chatGpt/playground",
  //   name: "ChatGPT Chatboot",
  //   component: ChatGPT_Chatboot,
  //   exact: true,
  // },
  // {
  //   path: "/gpt/chatGpt_prompts",
  //   name: "HaiX ChatGPT Prompts",
  //   component: CHATGPT_Prompts,
  //   exact: true,
  // },
  {
    /**
     * do not remove this two route which looks similar to you.
     * `/companyinsights/companydetails` and `/companyinsights/companydetails/:id` this 2 may look similar to you.
     * but if you remove anyone of them then the Apollo table page will break and will display white page to you.
     *
     * When you click on 'Sales lead insight' you will be re-directed to `/companyinsights/companydetails`.
     * After the page will be loaded the url will be change and you will be re-directed to `/companyinsights/companydetails/:id`.
     * Eveytime you click on `tabs` the url will change and it re-render(update table with API response) component on this `/companyinsights/companydetails/:id` url.
     *
     * if you have any good alternative for this then you are welcome to change it.
     * if you still have any questions ask me.
     *
     * -sarfaraj [MrDark]
     */
    path: '/companyinsights/companydetails',
    name: 'Company Details',
    component: CompanyDetailPage,
    exact: true,
  },
  {
    path: '/companyinsights/companydetails/:id',
    name: 'Company Details',
    component: CompanyDetailPage,
    exact: true,
  },
  {
    path: '/haix-genai/summary',
    name: 'genai summary',
    component: AiFullPage,
    exact: true,
  },

  /**
   * This is end.
   */
];

export const USER_PROFILE_PREFERENCE_MAPPING = [
  {
    id: 1,
    header: 'Manage Connected Accounts',
    component: ConnectedAccounts,
    path: '/profile',
  },

  {
    id: 2,
    header: 'Manage Portfolio/Companies',
    component: ManageCompaniesView,
    path: '/portfolio/trackcompanies',
  },
  {
    id: 12,
    header: 'Group Accounts/Keywords/Hashtags',
    component: ManageCompaniesViewNew,
    path: '',
  },
  {
    id: 13,
    header: 'Add Group Accounts/Keywords/Hashtags',
    component: ManageCompaniesViewGroup,
    path: '/portfolio/trackcompaniesgroup',
  },
  {
    id: 11,
    header: 'Manage Subscription',
    component: ManageSubscription,
    path: '/profile/subscription',
  },
  {
    id: 10,
    header: 'Manage LeadInsightsIQ Subscription',
    component: ManageLeadIQSubscription,
    path: '',
  },
  {
    id: 9,
    header: 'Manage Linkedin Subscription',
    component: ManageLinkedinSubscription,
    path: '/profile/linkedinSubscription',
  },

  {
    id: 91,
    header: 'Manage HaiX Gen AI Copilot Subscription',
    component: ManageCopilotSubscription,
    path: '/profile/genaiSubscription',
  },

  {
    id: 3,
    header: 'Manage Lead (Linkedin/Sales Navigator) Keywords',
    component: ManageSalesNavigatorsView,
    path: '/portfolio/tracksalesnavigators',
  },
  {
    id: 5,
    header: 'Manage Job Searches (Linkedin)',
    component: ManageJobSearchesView,
    path: '',
  },
  {
    id: 6,
    header: 'Manage Company Intelligence Searches',
    component: ManageCompanyDetailsView,
    path: '',
  },
  {
    id: 7,
    header: 'Manage System Settings',
    component: AppPreferencesView,
    path: '',
  },
  {
    id: 8,
    header: 'Manage Features',
    component: ManageFeaturesView,
    path: '',
  },
];
