import './FlipCard.css'; // Import the CSS file

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@uirack/components/ui/avatar';
import {
  BellIcon,
  CheckIcon,
  InfoCircledIcon,
  Pencil1Icon,
} from '@radix-ui/react-icons';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@uirack/components/ui/card';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@uirack/components/ui/popover';
import React, { useContext, useEffect, useState } from 'react';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@uirack/components/ui/sheet';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@uirack/components/ui/tooltip';

import { AiOutlineClose } from 'react-icons/ai';
import { Button } from '@uirack/components/ui/button';
import { Check } from 'lucide-react';
import { Checkbox } from '@uirack/components/ui/checkbox';
import DataList from '../InsightsDashboard/SearchContainer/DataList';
import NumOfPostComments from '../InsightsDashboard/SearchContainer/NumOfPostComments';
import NumOfPostCommentsNewMultiKeyword from '../InsightsDashboard/SearchContainer/NumOfPostCommentsNewMultiKeyword';
import Options from '../Components/Options';
import PropTypes from 'prop-types';
import { SOCIAL_MEDIA_TYPE } from '../../common/constants';
import { Switch } from '@uirack/components/ui/switch';
import { cn } from '@uirack/lib/utils';
import useId from './useId';
import useSearchTermCustomHook from '../customHooks/useSearchTerm.customHook';
import { v4 as uuidv4 } from 'uuid';

// import {
//   Command,
//   CommandEmpty,
//   CommandGroup,
//   CommandInput,
//   CommandItem,
//   CommandList,
//   CommandSeparator,
//   CommandShortcut,
// } from '@uirack/components/ui/command';

const notifications = [
  {
    title: 'User Account',
    description: 'nestle',
    helpText: 'this is to set so and so parameter in search',
  },
  {
    title: 'User Tag',
    description: 'nestle',
    helpText: 'this is to set so and so parameter in search',
  },
  {
    title: 'HashTag',
    description: 'nestle',
    helpText: 'this is to set so and so parameter in search',
  },
];

const NewChannelChooser = ({
  mediaItem,
  socialMediaSearchValues,
  searchTerm,
  mainSearchTerm,
  companyInfo,
  setSocialMediaSearchValues,
  setMediaSearchInput,
  showNew,
  masterSearchQuery,
  option,
  onMediaSourceToggle,
  handleOptionSelect,
  youtubeOption,
  twitterOption,
  tiktokOption,
  instagramOption,
  facebookOption,
  setInstagramType,
  setTiktokType,
  setYoutubeType,

  className,
  ...props
}) => {
  console.log(
    companyInfo,

    'Testkahdkjsh',
  );

  // ================================Var==========================
  // const ids = useId()
  const { searchTermLoader, searchTermResponse, handleSearch, error } =
    useSearchTermCustomHook();
  const { icon, label, state, id } = mediaItem;
  const ids = useId();
  const [searchKey, setSearchKey] = useState(
    masterSearchQuery || mainSearchTerm,
  );
  const [currentSelection, setCurrentSelection] = useState();
  const [isActiveChannel, setIsActiveChannel] = useState(true);
  const [isActiveTab, setIsActiveTab] = useState([]);
  const [isActive, setIsActive] = useState();

  const [open, setOpen] = useState(null);

  const [keywordHandler, setKeywordHandler] = useState({
    userAccount:
      mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
        ? companyInfo?.tiktokType?.userAccount || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
        ? companyInfo?.instagramType?.userAccount || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube
        ? companyInfo?.youtubeType?.channel || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.twitter
        ? companyInfo?.mediaSearchKeys?.twitter || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.facebook
        ? companyInfo?.mediaSearchKeys?.facebook || ''
        : mediaItem.label.toLowerCase() == 'news'
        ? companyInfo?.mediaSearchKeys?.news || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.reddit
        ? companyInfo?.mediaSearchKeys?.reddit || ''
        : '',

    userTag:
      mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
        ? companyInfo?.tiktokType?.userTag || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
        ? companyInfo?.instagramType?.userTag || ''
        : '',
    keyword:
      mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
        ? companyInfo?.tiktokType?.keyword || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
        ? companyInfo?.instagramType?.keyword || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube
        ? companyInfo?.youtubeType?.keyword || ''
        : '',
    hashtag:
      mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
        ? companyInfo?.tiktokType?.hashtag || ''
        : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
        ? companyInfo?.instagramType?.hashtag || ''
        : '',
  });
  const [inputValue, setInputValue] = useState(mainSearchTerm);
  useEffect(() => {
    // if (mediaItem.label.toLowerCase() != "facebook") {
    setKeywordHandler({
      userAccount:
        mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
          ? companyInfo?.tiktokType?.userAccount || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
          ? companyInfo?.instagramType?.userAccount || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube
          ? companyInfo?.youtubeType?.channel || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.twitter
          ? companyInfo?.mediaSearchKeys?.twitter || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.facebook
          ? companyInfo?.mediaSearchKeys?.facebook || ''
          : mediaItem.label.toLowerCase() == 'news'
          ? companyInfo?.mediaSearchKeys?.news || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.reddit
          ? companyInfo?.mediaSearchKeys?.reddit || ''
          : '',

      userTag:
        mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
          ? companyInfo?.tiktokType?.userTag
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
          ? companyInfo?.instagramType?.userTag || ''
          : '',
      keyword:
        mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
          ? companyInfo?.tiktokType?.keyword || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
          ? companyInfo?.instagramType?.keyword || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube
          ? companyInfo?.youtubeType?.keyword || ''
          : '',
      hashtag:
        mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
          ? companyInfo?.tiktokType?.hashtag || ''
          : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
          ? companyInfo?.instagramType?.hashtag || ''
          : '',
    });
    // }
  }, [companyInfo]);

  const [value, setValue] = useState('');
  // =============================================================

  console.log(
    mediaItem,
    searchTerm,
    searchTermResponse,
    icon,
    label,
    state,
    id,
    'propsTest',
  );

  const [stats, setStats] = useState(
    mediaItem.label.toLowerCase() === SOCIAL_MEDIA_TYPE.tiktok
      ? !!companyInfo?.tiktokType?.userAccount ||
          !!companyInfo?.tiktokType?.keyword ||
          !!companyInfo?.tiktokType?.hashtag
      : mediaItem.label.toLowerCase() === SOCIAL_MEDIA_TYPE.instagram
      ? !!companyInfo?.instagramType?.userAccount ||
        !!companyInfo?.instagramType?.userTag ||
        !!companyInfo?.instagramType?.hashtag
      : mediaItem.label.toLowerCase() === SOCIAL_MEDIA_TYPE.youtube
      ? !!companyInfo?.youtubeType?.channel ||
        !!companyInfo?.youtubeType?.keyword
      : false, // Fallback value if no condition matches
  );
  // ======check state ===
  function checkKeywordHandler(keywordHandler) {
    const allEmpty = Object.values(keywordHandler).every(
      (value) => value === '',
    );
    return !allEmpty;
  }
  // useEffect(() => {
  //   setStats(checkKeywordHandler(keywordHandler));
  // }, [keywordHandler]);

  const handleSwitchChange = (newState) => {
    setStats(newState);
    if (!newState) {
      onMediaSourceToggle(mediaItem.id, false);
      setKeywordHandler((prev) => {
        const resetFields = Object.keys(prev).reduce((acc, key) => {
          acc[key] = ''; // Reset each field to empty string
          return acc;
        }, {});

        return resetFields;
      });
    }
  };

  const checkedHandler = (isChecked, fieldName) => {
    console.log(isChecked, fieldName, 'kjkjkj');
    if (isChecked) {
      // handleOpenChange(fieldName);
      setKeywordHandler((prev) => ({
        ...prev,
        [fieldName]: inputValue || masterSearchQuery,
      }));
      // handleSearch(
      //   searchKey,
      //   mediaItem.label.toLowerCase(),
      //   fieldName == 'hashtag' ? true : undefined,
      //   isActiveChannel,
      // );
    } else {
      setKeywordHandler((prev) => ({
        ...prev,
        [fieldName]: isChecked ? prev[fieldName] : '',
      }));
    }
  };
  const handleMediaSearch = (value) => {
    setSearchKey(value);
    setInputValue(value);
    setKeywordHandler((prevState) => ({
      ...prevState,
      userAccount: value,
      userTag: value,
      keyword: value,
      hashtag: value,
    }));
  };
  // =================openKeyword Options===================

  useEffect(() => {
    updateInstagramOption(currentSelection, isActive);
  }, [currentSelection]);

  const handleOpenChange = (cardId) => {
    setOpen(cardId); // Open the clicked card and close others
  };

  const handleChannelClick = (dataItem, keyword) => {
    setOpen(false);
    setIsActive(keyword);

    if (dataItem.value === currentSelection) {
      setCurrentSelection();
      setMediaSearchInput(mediaItem.label.toLowerCase(), undefined);
    } else {
      setCurrentSelection(dataItem.value);
      setMediaSearchInput(
        mediaItem.label.toLowerCase(),
        dataItem.value,
        dataItem.value,
      );
    }
  };
  function updateInstagramOption(currentSelection, tabName) {
    setKeywordHandler((prevHandler) => {
      // Check if the key already exists and currentSelection is not empty
      if (currentSelection != '' && currentSelection != undefined) {
        // Key exists, update with the latest non-empty value
        setIsActiveTab([...isActiveTab, tabName]);
        return {
          ...prevHandler,
          [tabName]: currentSelection,
        };
      } else {
        // Key doesn't exist, add it with the currentSelection if it's not empty
        return {
          ...prevHandler,
          [tabName]: currentSelection,
        };
      }
      // else {
      //   // Key doesn't exist, and currentSelection is empty, do not update
      //   return prevHandler;
      // }
    });
  }
  // ====================================

  // =======================save in api ======================
  useEffect(() => {
    if (mediaItem.label.toLowerCase() == 'tiktok') {
      setTiktokType(keywordHandler);
    }
    if (mediaItem.label.toLowerCase() == 'instagram') {
      setInstagramType(keywordHandler);
    }
  }, [keywordHandler]);

  useEffect(() => {
    if (mediaItem.label.toLowerCase() === SOCIAL_MEDIA_TYPE.youtube) {
      setYoutubeType({
        channel: keywordHandler.userAccount,
        keyword: keywordHandler.keyword,
      });
    }
  }, [keywordHandler, mediaItem.label]);
  // ===========================================================
  console.log(mediaItem.label, keywordHandler, state, 'aklsdljalskdjlk');
  const [isFlipped, setIsFlipped] = useState(false);

  // Function to toggle flip state
  const handleFlipToggle = () => {
    setIsFlipped(!isFlipped);
  };
  return (
    <>
      <Card
        className={cn(
          'w-[280px] h-[400px] flex flex-col flip-card-container bg-white',
          'flip-card-inner',
          { flipped: isFlipped },
          className,
        )}
        {...props}
        id={ids}
      >
        <div className="flip-card-front">
          <CardHeader className="p-4 pb-0">
            <CardTitle>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <div
                    className={stats ? '' : 'opacity-50 pointer-events-none'}
                  >
                    <Avatar className="flex justify-center items-center">
                      <AvatarImage
                        className="h-6 w-6"
                        src={`/icons/social-media-icons/${
                          state || isActiveChannel ? 'colored' : 'glyph'
                        }/${icon}.svg`}
                      />
                      <AvatarFallback>{label}</AvatarFallback>
                    </Avatar>
                  </div>
                  <CardDescription
                    className={stats ? '' : 'opacity-50 pointer-events-none'}
                  >
                    <div className="mx-4">
                      {masterSearchQuery ||
                        socialMediaSearchValues[
                          mediaItem?.label.toLowerCase()
                        ] ||
                        searchTerm ||
                        mainSearchTerm}
                    </div>
                    {!showNew && (
                      <Sheet>
                        <SheetTrigger asChild>
                          <Button
                            variant="link"
                            className="text-xs -my-1 text-muted"
                            onClick={handleFlipToggle}
                          >
                            Available Data
                          </Button>
                        </SheetTrigger>
                        {/* <SheetContent>
                        <SheetHeader>
                          <SheetTitle>Available Data</SheetTitle>
                        </SheetHeader>
                        {companyInfo?.[mediaItem.label.toLowerCase()] && (
                          <NumOfPostComments
                            socialMedia={mediaItem.label.toLowerCase()}
                            mainSearchTerm={mainSearchTerm}
                          />
                        )}
                      </SheetContent> */}
                      </Sheet>
                    )}
                  </CardDescription>
                </div>
                <div className="flex-end">
                  <div className="text-black visible">
                    <Switch
                      checked={stats}
                      onCheckedChange={handleSwitchChange}
                      className="bg-gray-500"
                    />
                  </div>
                </div>
              </div>
            </CardTitle>
          </CardHeader>
          {/* ============================Input Box======================= */}
          <div
            className={cn({
              'pointer-events-none opacity-50': !stats, // Disable interaction and make it semi-transparent when stats is false
            })}
          >
            <div
              className={`relative flex items-center justify-center mx-[15%] ${
                mediaItem.state && 'mt-4'
              } ${isActiveChannel && 'mt-4'}} `}
            >
              <input
                type="text"
                onFocus={() => {
                  onMediaSourceToggle(mediaItem.id, true);
                  setIsActiveChannel(true);
                }}
                value={
                  inputValue ||
                  masterSearchQuery ||
                  socialMediaSearchValues[mediaItem.label.toLowerCase()] ||
                  searchTerm ||
                  mainSearchTerm
                }
                name={`${mediaItem.label}-search`}
                onChange={(e) => {
                  handleMediaSearch(e.target.value);
                }}
                className={`rounded-md appearance-none border border-primary text-base focus:outline-none focus:ring-1 pt-1 pb-1`}
              />
              {/* <div className="absolute right-3 top-2 pointer-events-none text-primary">
          <span className="material-icons text-md">edit</span>
        </div> */}
            </div>

            {/* ================================================================== */}

            {/* ===============Center========================= */}
            <div className="pt-4">
              {(mediaItem.state || isActiveChannel) &&
                (mediaItem.label.toLowerCase() === 'youtube' ||
                  mediaItem.label.toLowerCase() === 'twitter' ||
                  mediaItem.label.toLowerCase() === 'news' ||
                  mediaItem.label.toLowerCase() === 'reddit' ||
                  mediaItem.label.toLowerCase() === 'facebook' ||
                  mediaItem.label.toLowerCase() === 'tiktok' ||
                  mediaItem.label.toLowerCase() === 'instagram') && (
                  <CardContent className="grid gap-4 pb-2">
                    {/* =======================userAccount========================== */}
                    <div>
                      {(mediaItem.label.toLowerCase() === 'youtube' ||
                        mediaItem.label.toLowerCase() === 'tiktok' ||
                        mediaItem.label.toLowerCase() === 'instagram') && (
                        <div className="mb-2 grid grid-cols-[25px_1fr_25px] items-start justify-between pb-2 last:mb-0 last:pb-0">
                          <Checkbox
                            checked={keywordHandler.userAccount !== ''}
                            onCheckedChange={
                              (isChecked) =>
                                checkedHandler(isChecked, 'userAccount') // Handle checkbox change
                            }
                          />
                          <label
                            htmlFor={
                              (mediaItem.label.toLowerCase() === 'youtube' &&
                                'youtubemode') ||
                              (mediaItem.label.toLowerCase() === 'twitter' &&
                                'twittermode') ||
                              (mediaItem.label.toLowerCase() === 'tiktok' &&
                                'tiktokmode') ||
                              (mediaItem.label.toLowerCase() === 'instagram' &&
                                'instagrammode') ||
                              (mediaItem.label.toLowerCase() === 'facebook' &&
                                'facebookmode')
                            }
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          >
                            <div className="space-y-1">
                              <p className="flex text-sm font-medium leading-none">
                                {(mediaItem.label.toLowerCase() === 'youtube' &&
                                  'Channel') ||
                                  (mediaItem.label.toLowerCase() ===
                                    'twitter' &&
                                    'User Handler') ||
                                  (mediaItem.label.toLowerCase() === 'tiktok' &&
                                    'User Account') ||
                                  (mediaItem.label.toLowerCase() ===
                                    'instagram' &&
                                    'User Account')}
                              </p>

                              <p className="flex items-center text-sm text-muted-foreground">
                                {keywordHandler?.userAccount?.length > 10
                                  ? `${keywordHandler.userAccount.slice(
                                      0,
                                      10,
                                    )}..`
                                  : keywordHandler.userAccount ||
                                    inputValue ||
                                    masterSearchQuery}

                                <Popover
                                  key={'userAccount'}
                                  open={open === 'userAccount'}
                                  onOpenChange={() =>
                                    handleOpenChange('userAccount')
                                  }
                                >
                                  <PopoverTrigger asChild>
                                    <Button
                                      variant="ghost"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleSearch(
                                          searchKey,
                                          mediaItem.label.toLowerCase(),
                                          undefined,
                                          isActiveChannel,
                                        );
                                      }}
                                      aria-label="Edit"
                                    >
                                      <Pencil1Icon />
                                    </Button>
                                  </PopoverTrigger>
                                  <PopoverContent className="w-[280px] h-[400px] p-2  bg-white  overflow-auto ml-[60px] custom-scrollbarsmallCard">
                                    <div className="flex items-start justify-end mr-2">
                                      <button onClick={() => setOpen(false)}>
                                        <AiOutlineClose color="#ff0000" />
                                      </button>
                                    </div>
                                    {searchTermLoader ? (
                                      <div className="flex justify-center items-center w-full h-full">
                                        <div className="loader"></div>{' '}
                                        {/* Replace with your loader component or spinner */}
                                      </div>
                                    ) : searchTermResponse.length > 0 ? (
                                      searchTermResponse.map((resultItem) => (
                                        <DataList
                                          key={uuidv4()}
                                          dataItem={resultItem}
                                          handleChannelClick={(channel) =>
                                            handleChannelClick(
                                              channel,
                                              'userAccount',
                                            )
                                          }
                                          currentSelection={currentSelection}
                                        />
                                      ))
                                    ) : (
                                      <div className="flex justify-center items-center w-full h-full">
                                        <span>No results found</span>{' '}
                                        {/* Message when no data is found */}
                                      </div>
                                    )}
                                    {/* {searchTermResponse.map((resultItem) => (
                                      <DataList
                                        key={uuidv4()}
                                        dataItem={resultItem}
                                        handleChannelClick={(channel) =>
                                          handleChannelClick(
                                            channel,
                                            'userAccount',
                                          )
                                        }
                                        currentSelection={currentSelection}
                                      />
                                    ))} */}
                                  </PopoverContent>
                                </Popover>
                              </p>
                            </div>
                          </label>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button variant="ghost" size="icon">
                                  <InfoCircledIcon className="h-4 w-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent className="bg-gray-200 text-black">
                                <p>{keywordHandler?.userAccount}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      )}
                    </div>

                    {/* ========================userTag========================== */}
                    <div>
                      {mediaItem.label.toLowerCase() === 'instagram' ? (
                        <div className="mb-2 grid grid-cols-[25px_1fr_25px] items-start justify-between pb-2 last:mb-0 last:pb-0">
                          <Checkbox
                            checked={keywordHandler.userTag !== ''}
                            onCheckedChange={
                              (isChecked) =>
                                checkedHandler(isChecked, 'userTag') // Handle checkbox change
                            }
                          />

                          <div className="space-y-1">
                            <p className="flex text-sm font-medium leading-none">
                              User Tag
                            </p>

                            {/* {keywordHandler?.userTag && ( */}
                            <p className="flex items-center text-sm text-muted-foreground">
                              {keywordHandler?.userTag?.length > 10
                                ? `${keywordHandler.userTag.slice(0, 10)}..`
                                : keywordHandler.userTag ||
                                  inputValue ||
                                  masterSearchQuery}

                              <Popover
                                key={'userTag'}
                                open={open === 'userTag'}
                                onOpenChange={() => handleOpenChange('userTag')}
                              >
                                <PopoverTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSearch(
                                        searchKey,
                                        mediaItem.label.toLowerCase(),
                                        undefined,
                                        isActiveChannel,
                                      );
                                    }}
                                    aria-label="Edit"
                                  >
                                    <Pencil1Icon />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent className="w-[280px] h-[400px] p-2  bg-white  overflow-auto ml-[60px] custom-scrollbarsmallCard">
                                  <div className="flex items-start justify-end mr-2">
                                    <button onClick={() => setOpen(false)}>
                                      <AiOutlineClose color="#ff0000" />
                                    </button>
                                  </div>
                                  {searchTermLoader ? (
                                    <div className="flex justify-center items-center w-full h-full">
                                      <div className="loader"></div>{' '}
                                      {/* Replace with your loader component or spinner */}
                                    </div>
                                  ) : searchTermResponse.length > 0 ? (
                                    searchTermResponse.map((resultItem) => (
                                      <DataList
                                        key={uuidv4()}
                                        dataItem={resultItem}
                                        handleChannelClick={(channel) =>
                                          handleChannelClick(channel, 'userTag')
                                        }
                                        currentSelection={currentSelection}
                                      />
                                    ))
                                  ) : (
                                    <div className="flex justify-center items-center w-full h-full">
                                      <span>No results found</span>{' '}
                                      {/* Message when no data is found */}
                                    </div>
                                  )}
                                  {/* {searchTermResponse.map((resultItem) => (
                                      <DataList
                                        key={uuidv4()}
                                        dataItem={resultItem}
                                        handleChannelClick={(channel) =>
                                          handleChannelClick(
                                            channel,
                                            'userAccount',
                                          )
                                        }
                                        currentSelection={currentSelection}
                                      />
                                    ))} */}
                                </PopoverContent>
                              </Popover>
                            </p>
                            {/* )} */}
                          </div>

                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button variant="ghost" size="icon">
                                  <InfoCircledIcon className="h-4 w-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent className="bg-gray-200 text-black">
                                <p>{keywordHandler?.userTag}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      ) : (
                        (mediaItem.label.toLowerCase() === 'tiktok' ||
                          mediaItem.label.toLowerCase() === 'youtube') && (
                          <div className="mb-2 grid grid-cols-[25px_1fr_25px] items-start justify-between pb-2 last:mb-0 last:pb-0">
                            <Checkbox
                              checked={keywordHandler.keyword !== ''}
                              onCheckedChange={
                                (isChecked) =>
                                  checkedHandler(isChecked, 'keyword') // Handle checkbox change
                              }
                            />

                            <div className="space-y-1">
                              <p className="flex text-sm font-medium leading-none">
                                Keyword
                              </p>

                              {/* {keywordHandler?.keyword && ( */}
                              <p className="flex items-center text-sm text-muted-foreground">
                                {keywordHandler?.keyword?.length > 10
                                  ? `${keywordHandler.keyword.slice(0, 10)}..`
                                  : keywordHandler.keyword ||
                                    inputValue ||
                                    masterSearchQuery}
                                <Popover
                                  key={'keyword'}
                                  open={open === 'keyword'}
                                  onOpenChange={() =>
                                    handleOpenChange('keyword')
                                  }
                                >
                                  <PopoverTrigger asChild>
                                    <Button
                                      variant="ghost"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleSearch(
                                          searchKey,
                                          mediaItem.label.toLowerCase(),
                                          undefined,
                                          isActiveChannel,
                                        );
                                      }}
                                      aria-label="Edit"
                                    >
                                      <Pencil1Icon />
                                    </Button>
                                  </PopoverTrigger>
                                  <PopoverContent className="w-[280px] h-[400px] p-2  bg-white  overflow-auto ml-[60px] custom-scrollbarsmallCard">
                                    <div className="flex items-start justify-end mr-2">
                                      <button onClick={() => setOpen(false)}>
                                        <AiOutlineClose color="#ff0000" />
                                      </button>
                                    </div>
                                    {searchTermLoader ? (
                                      <div className="flex justify-center items-center w-full h-full">
                                        <div className="loader"></div>{' '}
                                        {/* Replace with your loader component or spinner */}
                                      </div>
                                    ) : searchTermResponse.length > 0 ? (
                                      searchTermResponse.map((resultItem) => (
                                        <DataList
                                          key={uuidv4()}
                                          dataItem={resultItem}
                                          handleChannelClick={(channel) =>
                                            handleChannelClick(
                                              channel,
                                              'keyword',
                                            )
                                          }
                                          currentSelection={currentSelection}
                                        />
                                      ))
                                    ) : (
                                      <div className="flex justify-center items-center w-full h-full">
                                        <span>No results found</span>{' '}
                                        {/* Message when no data is found */}
                                      </div>
                                    )}

                                    {/* {searchTermResponse.map((resultItem) => (
                                      <DataList
                                        key={uuidv4()}
                                        dataItem={resultItem}
                                        handleChannelClick={(channel) =>
                                          handleChannelClick(channel, 'keyword')
                                        }
                                        currentSelection={currentSelection}
                                      />
                                    ))} */}
                                  </PopoverContent>
                                </Popover>
                              </p>
                              {/* )} */}
                            </div>

                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button variant="ghost" size="icon">
                                    <InfoCircledIcon className="h-4 w-4" />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent className="bg-gray-200 text-black">
                                  <p>{keywordHandler.keyword}</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        )
                      )}
                    </div>
                    {/* ===================================================== */}

                    {/* =====================hashtag================================ */}
                    <div>
                      {mediaItem.label.toLowerCase() === 'instagram' ||
                      mediaItem.label.toLowerCase() === 'tiktok' ? (
                        <div
                          // key={index}
                          className="mb-2 grid grid-cols-[25px_1fr_25px] items-start justify-between pb-2 last:mb-0 last:pb-0"
                        >
                          <Checkbox
                            checked={keywordHandler.hashtag !== ''}
                            onCheckedChange={
                              (isChecked) =>
                                checkedHandler(isChecked, 'hashtag') // Handle checkbox change
                            }
                          />

                          <div className="space-y-1">
                            <p className="flex text-sm font-medium leading-none">
                              Hashtag
                            </p>

                            {/* {keywordHandler?.hashtag && ( */}
                            <p className="flex items-center text-sm text-muted-foreground">
                              {keywordHandler?.hashtag?.length > 10
                                ? `${keywordHandler.hashtag.slice(0, 10)}..`
                                : keywordHandler.hashtag ||
                                  inputValue ||
                                  masterSearchQuery}
                              <Popover
                                key={'hashtag'}
                                open={open === 'hashtag'}
                                onOpenChange={() => handleOpenChange('hashtag')}
                              >
                                <PopoverTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSearch(
                                        searchKey,
                                        mediaItem.label.toLowerCase(),
                                        true,
                                        isActiveChannel,
                                      );
                                    }}
                                    aria-label="Edit"
                                  >
                                    <Pencil1Icon />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent className="w-[280px] h-[400px] p-2  bg-white  overflow-auto ml-[60px] custom-scrollbarsmallCard">
                                  <div className="flex items-start justify-end mr-2">
                                    <button onClick={() => setOpen(false)}>
                                      <AiOutlineClose color="#ff0000" />
                                    </button>
                                  </div>
                                  {searchTermLoader ? (
                                    <div className="flex justify-center items-center w-full h-full">
                                      <div className="loader"></div>{' '}
                                      {/* Replace with your loader component or spinner */}
                                    </div>
                                  ) : searchTermResponse.length > 0 ? (
                                    searchTermResponse.map((resultItem) => (
                                      <DataList
                                        key={uuidv4()}
                                        dataItem={resultItem}
                                        handleChannelClick={(channel) =>
                                          handleChannelClick(channel, 'hashtag')
                                        }
                                        currentSelection={currentSelection}
                                      />
                                    ))
                                  ) : (
                                    <div className="flex justify-center items-center w-full h-full">
                                      <span>No results found</span>{' '}
                                      {/* Message when no data is found */}
                                    </div>
                                  )}

                                  {/* {searchTermResponse.map((resultItem) => (
                                    <DataList
                                      key={uuidv4()}
                                      dataItem={resultItem}
                                      handleChannelClick={(channel) =>
                                        handleChannelClick(channel, 'hashtag')
                                      }
                                      currentSelection={currentSelection}
                                    />
                                  ))} */}
                                </PopoverContent>
                              </Popover>
                            </p>
                            {/* )} */}
                          </div>

                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button variant="ghost" size="icon">
                                  <InfoCircledIcon className="h-4 w-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent className="bg-gray-200 text-black">
                                <p>{keywordHandler?.hashtag}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      ) : null}
                    </div>

                    {/* ===================================================== */}
                  </CardContent>
                )}
            </div>
            {/* ================================================ */}
          </div>
        </div>
        <div className="flip-card-back ">
          {/* {companyInfo?.[mediaItem.label.toLowerCase()] && ( */}
          <NumOfPostCommentsNewMultiKeyword
            socialMedia={mediaItem.label.toLowerCase()}
            mainSearchTerm={mainSearchTerm}
            companyInfo={companyInfo}
            handleFlipToggle={handleFlipToggle}
          />
          {/* )} */}
        </div>
      </Card>{' '}
      {/* <Button
        className={`mt-4 w-full bg-gray-200 text-black hover:bg-gray-800 hover:text-white ${
          stats ? '' : 'opacity-50 pointer-events-none'
        }`}
        onClick={}
      >
        <CheckIcon className="mr-2 h-4 w-4" /> Available Data
      </Button> */}
    </>
  );
};

NewChannelChooser.propTypes = {
  mediaItem: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    state: PropTypes.bool,
  }),
  option: PropTypes.bool,
  masterSearchQuery: PropTypes.string,
  onMediaSourceToggle: PropTypes.func,
  setMediaSearchInput: PropTypes.func,
  handleOptionSelect: PropTypes.func,
  socialMediaSearchValues: PropTypes.shape({
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    reddit: PropTypes.string,
    news: PropTypes.string,
    tiktok: PropTypes.string,
    linkedin: PropTypes.string,
    youtube: PropTypes.string,
  }),
  setSocialMediaSearchValues: PropTypes.func.isRequired,
};

export default React.memo(NewChannelChooser);
