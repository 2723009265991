import { useQuery } from "@tanstack/react-query";

/**
 *
 * @param {Array} queryKey
 * @param {Function} queryFn
 * @param {Object} options
 * @returns {Object} 
 */
const useReactQueryCustomHook = (queryKey, queryFn, options = {}) => {
  if (!queryKey.length || !queryKey)
    return new Error(
      "Query key is required. `queryKey` can not be empty or blank."
    );

  return useQuery(queryKey, queryFn, {
    ...options,
    staleTime: 1000 * 60 * 60 * 6, // after 6 hours react query will fetch API.
    initialDataUpdatedAt: Date.now(), // this has timestamp of last updated.
    refetchOnWindowFocus: false, // this tell react query do not fetch when focus on window.
  });
};

export default useReactQueryCustomHook;
